<template>
  <VDialog
    v-model="dialogOpened"
    class="patient-inquire-dialog"
    persistent
    max-width="500px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VToolbar dense dark class="primary">
        <VToolbarTitle>新增症狀</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="close()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VSimpleTable dense fixed-header>
        <!-- {{ simpleTable }} -->
        <template #default>
          <thead>
            <tr>
              <th class="text-center">症狀選項</th>
              <th class="text-center">內容</th>
              <th class="text-center">備註</th>
            </tr>
          </thead>
          <template v-if="simpleTableLength() > 0">
            <tbody v-for="(option, k) in simpleTableContent" :key="k">
              <tr>
                <td class="text-center">{{ option.option }}</td>
                <td class="text-center">{{ option.content }}</td>
                <td class="text-center">{{ option.remark }}</td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <tbody>
              <tr>
                <td colspan="3" class="text-center text--disabled">無資料</td>
              </tr>
            </tbody>
          </template>
        </template>
      </VSimpleTable>
      <VDivider />
      <VCardText>
        <VContainer fluid>
          <VRow>
            <VCol>
              <VRadioGroup v-if="item" v-model="addItem.option" mandatory>
                <VRadio
                  v-for="(op, i) in Object.keys(item).sort()"
                  :key="`option${i}`"
                  :label="op"
                  :value="op"
                  @click="setAddItem(op)"
                ></VRadio>
                <!-- :disabled="disabledRadioBtn(op)" -->
              </VRadioGroup>
            </VCol>
            <VCol>
              <VCardText>
                <VCheckbox
                  v-for="(content, i) in currentContentOptions"
                  :key="`content${i}`"
                  v-model="addItem.content"
                  dense
                  multiple
                  hide-details
                  :label="content"
                  :value="content"
                  class="mt-0"
                ></VCheckbox>
              </VCardText>
            </VCol>
          </VRow>
          <VRow>
            <VTextField
              v-model="addItem.remarks"
              clearable
              label="備註"
              prepend-icon="mdi-note"
            ></VTextField>
          </VRow>
        </VContainer>
      </VCardText>
      <VDivider />
      <VCardActions>
        <VBtn text color="red accent-2" @click="close()">取消</VBtn>
        <VSpacer />
        <VBtn text :disabled="addItem.content.length === 0" color="info" @click="addOneMore()">
          新增下一筆
        </VBtn>
        <VBtn text :disabled="addItem.content.length === 0" color="info" @click="submit()">
          確認新增
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';

export default Vue.extend({
  name: 'PatientInquireDialog',
  mixins: [ExtensionsMixin],
  data: () => ({
    addItem: {
      option: '',
      content: [],
      remarks: '',
    },
    requesting: false,
    dialogOpened: false,
    currentPickedMethod: '',
    item: {
      出汗: ['夜間盜汗'],
      口渴: ['不渴', '時常口渴'],
      大便: [
        '黑',
        '稀',
        '帶血',
        '便祕',
        '每日一次',
        '黏',
        '排便兩日以上',
        '顆粒狀',
        '溏瀉',
        '不易解乾淨',
      ],
      小便: ['黃', '透明', '帶血'],
      睡眠: ['不易入眠', '多夢', '眠少', '夜尿影響睡眠', '常打瞌睡', '嗜睡'],
      精神: ['憂鬱', '恍神'],
      食慾: ['厭食', '胃口好'],
    },
    simpleTable: {
      睡眠: { content: [], remarks: '' },
      精神: { content: [], remarks: '' },
      出汗: { content: [], remarks: '' },
      食慾: { content: [], remarks: '' },
      口渴: { content: [], remarks: '' },
      小便: { content: [], remarks: '' },
      大便: { content: [], remarks: '' },
    },
    toEdit: null,
  }),
  computed: {
    currentContentOptions() {
      if (this.addItem.option) {
        return this.item[this.addItem.option];
      }
      if (this.addItem.option === '' && this.item) {
        return Object.entries(this.item)[0][1];
      }
      return [];
    },
    simpleTableContent() {
      const contents = [];
      const symptomTable = this.getSymptomTable();
      symptomTable.diagnosis.wen.data.forEach((item) => {
        let symptoms = '';
        item[1].forEach((i, index) => {
          if (index === 0) {
            symptoms += i;
          } else {
            symptoms += `, ${i}`;
          }
        });
        contents.push({
          option: item[0],
          content: symptoms,
          remarks: item[2],
        });
      });
      return contents;
    },
  },
  created() {
    componentBus.$on('adjust-content', (name) => {
      if (name === '問') {
        if (this.getSymptomTable().diagnosis.wen.data.length > 0) {
          Object.keys(this.simpleTable).forEach((item) => {
            this.simpleTable[item].content = [];
            this.simpleTable[item].remarks = '';
          });
          const symptomTable = this.getSymptomTable();
          symptomTable.diagnosis.wen.data.forEach((item) => {
            const [label, content, remark] = item;
            this.simpleTable[label].content = content;
            this.simpleTable[label].remarks = remark;
          });
          this.setAddItem('睡眠');
        }
        this.dialogOpened = true;
      }
    });
    componentBus.$on('to-edit', (data) => {
      const [label, content, remark] = data;
      this.toEdit = label;
      this.addItem.option = label;
      this.addItem.content = content.split(', ');
      this.addItem.remarks = remark;
    });
  },
  methods: {
    setAddItem(op) {
      const symptomTable = this.getSymptomTable();
      if (symptomTable.diagnosis.wen.data.findIndex((item) => item[0] === op) === -1) {
        this.addItem.option = op;
        this.addItem.content = [];
        this.addItem.remarks = '';
      } else {
        const contents = symptomTable.diagnosis.wen.data.find((item) => item[0] === op);
        console.log(contents);
        const [label, content, remark] = contents;
        this.addItem.option = label;
        this.addItem.content = content;
        this.addItem.remarks = remark;
      }
    },
    simpleTableLength() {
      let counter = 0;
      Object.keys(this.simpleTable).forEach((item) => {
        counter += this.simpleTable[item].content.length;
      });
      this.testcount = counter;
      return counter;
    },
    submit() {
      // const picked = {};
      // picked[this.addItem.option] = {
      //   content: this.addItem.content,
      //   remarks: this.addItem.remarks,
      // };
      // this.$emit('add-array', picked);
      this.addOneMore();
      this.close();
    },
    addOneMore() {
      this.toEdit = null;
      this.simpleTable[this.addItem.option].content = this.addItem.content;
      this.simpleTable[this.addItem.option].remarks = this.addItem.remarks;
      const symptomTable = this.getSymptomTable();
      symptomTable.diagnosis.wen.data = symptomTable.diagnosis.wen.data.filter((data) => {
        return data[0] !== this.addItem.option;
      });
      symptomTable.diagnosis.wen.data.push([
        this.addItem.option,
        this.addItem.content,
        this.addItem.remarks,
      ]);
      symptomTable.diagnosis.wen.data.push('.');
      symptomTable.diagnosis.wen.data.pop();
      // const picked = {};
      // picked[this.addItem.option] = {
      //   content: this.addItem.content,
      //   remarks: this.addItem.remarks,
      // };
      // this.$emit('add-array', picked);
      // this.initData();
      if (symptomTable.diagnosis.wen.data.length === 7) {
        this.close();
      } else {
        this.addItem.option = Object.keys(this.item).find(
          (name) => symptomTable.diagnosis.wen.data.findIndex((dataa) => dataa[0] === name) === -1,
        );
        this.setAddItem(this.addItem.option);
      }
    },
    close() {
      this.dialogOpened = false;
      this.toEdit = null;
      // this.initData();
      this.$emit('input', false);
    },
    // initData() {
    //   this.addItem = {
    //     option: '',
    //     content: [],
    //     remarks: '',
    //   };
    // },
    disabledRadioBtn(op) {
      let disable = false;
      const symptomTable = this.getSymptomTable();
      symptomTable.diagnosis.wen.data.forEach((item) => {
        if (item[0] === op && op !== this.toEdit) {
          disable = true;
        }
      });
      return disable;
    },
    getSymptomTable() {
      const { currentPatientDiagnosis } = this.$store.state;
      const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return symptomTable;
    },
  },
});
</script>

<style scoped>
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 43%;
}
</style>
