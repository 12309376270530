<template class="patient-examination-dialog">
  <VDialog v-model="dialogOpened" max-width="750px" persistent>
    <VCard>
      <VToolbar dense dark class="primary">
        <VToolbarTitle>相類脈</VToolbarTitle>
        <VSpacer />
        <VBtn class="pr-3" text @click="submit(selected)">Submit</VBtn>
        <VBtn icon @click="close()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>

      <VCard>
        <!-- <p>{{ selected }}</p> -->
        <VDataTable
          v-model="selected"
          :headers="headers"
          :items="symptoms"
          item-key="maiGangName"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-select
          mobile-breakpoint
          dense
        />
      </VCard>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

export default Vue.extend({
  name: 'PatientExaminationDialog',
  data: () => ({
    selected: [],
    headers: [
      // {
      //   text: '脈綱',
      //   value: 'maiGangCategory',
      //   sortable: false,
      // },
      {
        text: '脈名',
        value: 'maiGangName',
        sortable: false,
      },
      {
        text: '脈像',
        value: 'maiGangSymptoms',
        sortable: false,
      },
      {
        text: '主病',
        value: 'problem',
        sortable: false,
      },
    ],
    symptoms: [
      // {
      //   maiGangCategory: '',
      //   maiGangName: '',
      //   maiGangSymptoms: '',
      //   problem: '',
      // },
      {
        maiGangName: '浮',
        maiGangSymptoms: '輕取即得，重按稍弱而不空',
        problem: '表證，亦主虛證',
      },
      {
        maiGangName: '洪',
        maiGangSymptoms: '洪脈極大，狀若波濤洶湧，來盛去衰',
        problem: '氣分熱盛',
      },
      {
        maiGangName: '濡',
        maiGangSymptoms: '浮而細軟',
        problem: '諸虛，又主濕',
      },
      {
        maiGangName: '散',
        maiGangSymptoms: '浮散無根，至數不齊',
        problem: '元氣離散',
      },
      {
        maiGangName: '孔',
        maiGangSymptoms: '浮大中空，如按蔥管',
        problem: '失血，傷陰',
      },
      {
        maiGangName: '革',
        maiGangSymptoms: '脈來弦急中空，如按鼓皮',
        problem: '亡血，失精，半產，漏下',
      },
      {
        maiGangName: '沉',
        maiGangSymptoms: '輕取不應，重按始得',
        problem: '裏證',
      },
      {
        maiGangName: '伏',
        maiGangSymptoms: '重手推筋按骨始得',
        problem: '邪閉，厥證，痛極，又主陽衰',
      },
      {
        maiGangName: '牢',
        maiGangSymptoms: '沉按實大弦長',
        problem: '陰寒內實，疝氣徵瑕',
      },
      {
        maiGangName: '弱',
        maiGangSymptoms: '極軟而沉細',
        problem: '氣血不足',
      },
      {
        maiGangName: '遲',
        maiGangSymptoms: '一息三至，脈來遲慢',
        problem: '寒證',
      },
      {
        maiGangName: '緩',
        maiGangSymptoms: '一息四至，來去怠緩',
        problem: '主濕，脾胃虛弱',
      },
      {
        maiGangName: '澀',
        maiGangSymptoms: '遲細而短，往來艱澀，與滑脈正反',
        problem: '傷精，血少，氣滯血瘀',
      },
      {
        maiGangName: '結',
        maiGangSymptoms: '脈來緩慢而有不規則的間歇',
        problem: '陰盛氣結，寒痰血瘀',
      },
      {
        maiGangName: '數',
        maiGangSymptoms: '一息脈來五至以上',
        problem: '熱證',
      },
      {
        maiGangName: '促',
        maiGangSymptoms: '脈來急促而有不規則的間歇',
        problem: '陽熱亢盛，氣滯血瘀',
      },
      {
        maiGangName: '疾',
        maiGangSymptoms: '脈來急疾，一息七八至',
        problem: '陽極陰竭，元氣將脫',
      },
      {
        maiGangName: '動',
        maiGangSymptoms: '脈形如豆，滑數有力',
        problem: '主驚，主痛',
      },
      {
        maiGangName: '虛',
        maiGangSymptoms: '三部脈舉之無力，按之空虛',
        problem: '虛證',
      },
      {
        maiGangName: '細',
        maiGangSymptoms: '脈細如線，但應指明顯',
        problem: '氣血兩虛，諸虛勞損，主濕',
      },
      {
        maiGangName: '微',
        maiGangSymptoms: '極細極軟，按之欲絕，若有若無',
        problem: '陽衰少氣，陰陽氣血諸虛',
      },
      {
        maiGangName: '代',
        maiGangSymptoms: '脈來有規律的間歇，間歇時間較長',
        problem: '臟氣衰微，跌打損傷',
      },
      {
        maiGangName: '短',
        maiGangSymptoms: '首尾俱短，不能滿部',
        problem: '有力為氣鬱，無力為氣損',
      },
      {
        maiGangName: '實',
        maiGangSymptoms: '三部脈舉按皆有力',
        problem: '實證',
      },
      {
        maiGangName: '滑',
        maiGangSymptoms: '往來流利，如盤走珠，應指圓滑',
        problem: '痰飲，時滯，實熱',
      },
      {
        maiGangName: '緊',
        maiGangSymptoms: '脈來繃急，狀如牽繩轉索',
        problem: '主寒，主痛，主宿食',
      },
      {
        maiGangName: '長',
        maiGangSymptoms: '首尾端直，超過本位',
        problem: '肝陽有餘，陽盛內熱等有餘之證',
      },
      {
        maiGangName: '弦',
        maiGangSymptoms: '端直以長，如按琴弦',
        problem: '肝膽病，諸痛，痰飲，瘧疾',
      },
    ],
    dialogOpened: false,
  }),
  computed: {},
  watch: {
    async selected(val, oldVal) {
      if (val.length > 4) {
        this.$nextTick().then(() => {
          this.selected = oldVal;
        });
      }
    },
  },
  created() {
    componentBus.$on('adjust-content', (name) => {
      if (name === '切') {
        const symptomTable = this.getSymptomTable();
        this.selected = [];
        symptomTable.diagnosis.qie.data.forEach((item) => {
          this.selected.push(this.symptoms.find((maiGang) => maiGang.maiGangName === item[1]));
        });
        this.dialogOpened = true;
      }
    });
  },
  methods: {
    close() {
      this.dialogOpened = false;
    },
    async submit() {
      const symptomTable = this.getSymptomTable();
      symptomTable.diagnosis.qie.data = [];
      this.selected.forEach((item) => {
        symptomTable.diagnosis.qie.data.push([item.maiGangSymptoms, item.maiGangName, '']);
      });
      symptomTable.diagnosis.qie.data.push('.');
      symptomTable.diagnosis.qie.data.pop();
      this.close();
    },
    getSymptomTable() {
      const { currentPatientDiagnosis } = this.$store.state;
      const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return symptomTable;
    },
  },
});
</script>

<style scoped>
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 43%;
}
</style>
