export default class TableTransform {
  constructor() {
    this.resetTable();
  }

  resetTable() {
    this.simpleTable = [];
  }

  setDataTable() {
    const methods = Object.values(this.tableData);
    methods.forEach((method, i) => {
      const options = Object.keys(method);
      const optionInfos = Object.values(method);
      const methodTemp = [];
      if (options.length === 0) {
        methodTemp.push([this.diagnosisType[i], '', '', '']);
      }
      options.forEach((option, row) => {
        const optionsTemp = [];
        if (row === 0) {
          this.diagnosisType[i].rowspan = options.length;
          option.push(this.diagnosisType[i]);
        }
        optionsTemp.push(options[row]);
        let content = '';
        optionInfos[row].content.forEach((item, ii) => {
          content = ii === 0 ? item : `${content}, ${item}`;
        });
        optionsTemp.push(content);
        optionsTemp.push(optionInfos[row].remarks);
        optionsTemp.push('mdi-delete');
        methodTemp.push(option);
      });
      this.simpleTable.push(method);
    });
  }

  clear() {
    this.resetTable();
  }

  update(diagnosisType, tableData) {
    this.diagnosisType = diagnosisType;
    this.tableData = tableData;
    this.resetTable();
    this.setDataTable();
  }
}
