<template>
  <div class="symptom-table">
    <VContainer fluid>
      <VCol>
        <VRow>
          <VCol
            v-for="(symptom, _, symptom_i) in symptomTableSimpleTable"
            :key="`${symptom_i}`"
            class="mb-3"
            align="center"
          >
            <VBtn
              fab
              x-small
              dark
              depressed
              color="primary"
              @click="addContent(symptom, symptom.name)"
            >
              <span class="text-subtitle-2">{{ symptom.name }}</span>
            </VBtn>
          </VCol>
        </VRow>
        <VDivider></VDivider>
        <VRow>
          <VCol>
            <p>{{ symptomTableText }}</p>
          </VCol>
        </VRow>
      </VCol>
    </VContainer>
    <!-- Table view incase regrets -->
    <!--
      <VSimpleTable dense fixed-header>
      <template #default>
      <thead>
      <tr>
      <th width="32px" class="pa-1" style="border-right: thin solid rgba(0, 0, 0, 0.12)" />
      <th class="text-center">症狀選項</th>
      <th class="text-center">內容</th>
      <th class="text-center">備註</th>
      <th class="text-right">編輯</th>
      </tr>
      </thead>

      <tbody
      v-for="(symptom, _, symptom_i) in symptomTableSimpleTable"
      :key="`${symptom_i}`"
      class="text-center"
      >
      <tr>
      <td
      :rowspan="symptom.data.length"
      style="border-right: thin solid rgba(0, 0, 0, 0.12)"
      class="pa-1"
      >
      <VBtn
      fab
      x-small
      dark
      depressed
      color="primary"
      @click="addContent(symptom, symptom.name)"
      >
      <span class="text-subtitle-2">{{ symptom.name }}</span>
      </VBtn>
      </td>
      <template v-if="symptom.data[0]">
      <td v-for="s in arrayToString(symptom.data[0])" :key="s">{{ s }}</td>
      </template>
      <template v-else>
      <td v-for="s in symptom.data[0]" :key="s">{{ s }}</td>
      </template>
      <td v-if="symptom.data[0]" class="text-right">
      <VBtn
      fab
      x-small
      dark
      text
      depressed
      color="error"
      @click="deleteOption(symptom.data[0], symptom_i)"
      >
      <VIcon>mdi-delete</VIcon>
      </VBtn>
      <VBtn
      fab
      x-small
      dark
      text
      depressed
      color="primary"
      @click="editOption(symptom.name, symptom_i, symptom.data[0])"
      >
      <VIcon>mdi-clipboard-edit-outline</VIcon>
      </VBtn>
      </td>
      </tr>
      <template v-if="symptom.data[1]">
      <tr v-for="(content, content_i) in symptomContents(symptom.data)" :key="content_i">
      <td v-for="s in content" :key="s">{{ s }}</td>
      <td class="text-right">
      <VBtn
      fab
      x-small
      dark
      text
      depressed
      color="error"
      @click="deleteOption(content, symptom_i)"
      >
      <VIcon>mdi-delete</VIcon>
      </VBtn>
      <VBtn
      fab
      x-small
      dark
      text
      depressed
      color="primary"
      @click="editOption(symptom.name, symptom_i, content)"
      >
      <VIcon>mdi-clipboard-edit-outline</VIcon>
      </VBtn>
      </td>
      </tr>
      </template>
      </tbody>
      </template>
      </VSimpleTable> 
    -->
    <PatientInquireDialog />
    <PatientExaminationDialog />
    <PatientObservationDialog />
    <ImageTableDialog />
  </div>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

// import AddSymptomDialog from '@/components/Diagnosis/AddSymptomDialog';
import ExtensionsMixin from '@/mixins/ExtensionsMixin';
import TableTransform from '@/models/TableTransform';

import ImageTableDialog from '@/components/Diagnosis/ImageTableDialog';
import PatientExaminationDialog from '@/components/Diagnosis/PatientExaminationDialog';
import PatientInquireDialog from '@/components/Diagnosis/PatientInquireDialog';
import PatientObservationDialog from '@/components/Diagnosis/PatientObservationDialog';

export default Vue.extend({
  name: 'SymptomTable',
  components: {
    PatientExaminationDialog,
    PatientInquireDialog,
    PatientObservationDialog,
    ImageTableDialog,
  },
  mixins: [ExtensionsMixin],
  props: {
    value: { type: Object, default: () => {} },
  },
  data: () => ({
    symptomTable: new TableTransform(),
    addData: [],
    requesting: false,
    addInfo: null,
    addDialogOpened: false,
    pickedMethodIdx: null,
    currentPickedMethod: null,
    selected: [],
  }),
  computed: {
    diagnosisInfo() {
      return this.$store.state.symptomTableSimpleTable[this.$store.state.patient][1];
    },
    diagnosisType() {
      if (this.$store.state.diagnosisInfo) {
        return this.$store.state.diagnosisInfo.methods;
      }
      return [];
    },
    symptomTableSimpleTable() {
      const symptomTable = this.getSymptomTable();
      return symptomTable.diagnosis;
    },
    symptomTableText() {
      const symptoms = this.getSymptomTable().diagnosis;
      let text = '';
      Object.keys(symptoms).forEach((s) => {
        if (symptoms[s].data.length > 0) {
          const currentEntry = symptoms[s].data;
          currentEntry.forEach((entry) => {
            if (s === 'qie') {
              text += `${entry[1]} : ${entry[0]}. `;
            } else {
              text += `${entry[0]} : ${entry[1]}. `;
            }
          });
        }
      });
      return text;
    },
  },
  watch: {
    addData(val) {
      if (val) {
        this.$emit('input', this.value);
        this.symptomTable.update(this.diagnosisType, this.value);
      }
    },
  },
  async created() {
    // this.value[this.currentPickedMethod] = {
    //   ...this.value[this.currentPickedMethod],
    //   ...val,
    // };
    // await this.$store.dispatch('bindDiagnosisInfo', this.$store.state.diagnosisId);
    // this.value
    this.symptomTable.update(this.diagnosisType, this.value);
  },
  methods: {
    addContent(item, name) {
      componentBus.$emit('adjust-content', name);
      this.currentPickedMethod = item.name;
      this.addInfo = item.options;
    },
    deleteOption(content, symptomIndex) {
      // console.log('delete pressed');
      // const newTable = this.$store.state.symptomTableSimpleTable[symptomIndex][1].filter(
      const symptomTable = this.getSymptomTable();
      // symptomIndex
      let currentSymptom = null;
      if (symptomIndex === 0) currentSymptom = symptomTable.diagnosis.wen;
      else if (symptomIndex === 1) currentSymptom = symptomTable.diagnosis.qie;
      else if (symptomIndex === 2) currentSymptom = symptomTable.diagnosis.wang;
      else if (symptomIndex === 3) currentSymptom = symptomTable.diagnosis.yan;
      else if (symptomIndex === 4) currentSymptom = symptomTable.diagnosis.she;
      const newTable = currentSymptom.data.filter((option) => {
        return (
          this.arrayToString(option)[1] !== content[1] &&
          this.arrayToString(option)[0] !== content[0]
        );
      });
      currentSymptom.data = newTable;
      // TODO - make this not stupid
      currentSymptom.data.push('.');
      currentSymptom.data.pop();
    },
    editOption(name, symptomIndex, data) {
      if (symptomIndex === 0) {
        componentBus.$emit('to-edit', data);
      }
      componentBus.$emit('adjust-content', name);
    },
    arrayToString(value) {
      if (typeof value[1] === 'string') {
        return value;
      }
      const temp = [];
      temp.push(value[0]);
      let string = '';
      value[1].forEach((item, i) => {
        if (i === 0) {
          string += item;
        } else {
          string += `, ${item}`;
        }
      });
      temp.push(string);
      temp.push(value[2]);
      return temp;
    },
    symptomContents(symptom) {
      // console.log(symptom);
      const items = [];
      symptom.forEach((value, index) => {
        if (index !== 0) {
          if (typeof value[1] === 'string') {
            items.push(value);
          } else {
            items.push(this.arrayToString(value));
          }
        }
      });
      return items;
    },
    getSymptomTable() {
      const { currentPatientDiagnosis } = this.$store.state;
      // console.log(currentPatientDiagnosis);
      const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return symptomTable;
    },
  },
});
</script>
