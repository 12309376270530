<template>
  <VDialog
    class="app-about-dialog"
    max-width="400px"
    :value="value"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) in $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>
    <VCard>
      <VToolbar color="primary" dark dense>
        <VIcon left>mdi-information</VIcon>
        <VToolbarTitle>About</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="close()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VContainer class="d-flex justify-center pb-0" fluid>
        <VIcon x-large :color="$store.state.app.themeColor">mdi-{{ $store.state.app.icon }}</VIcon>
      </VContainer>
      <VCardTitle class="justify-center text-h5">{{ $store.state.app.name }}</VCardTitle>
      <VCardSubtitle class="text-center text-subtitle-1">
        Version: {{ $store.state.app.package.version }}
      </VCardSubtitle>
      <VCardText class="text--primary">
        <div class="font-weight-medium">Developers:</div>
        <ul>
          <li v-for="(contributor, i) in $store.state.app.package.contributors" :key="i">
            {{ contributor.name }}:
            <a class="d-inline-block" :href="contributor.url">Website</a>
            &sol;
            <a class="d-inline-block" :href="`mailto:${contributor.email}`">Email</a>
          </li>
        </ul>
      </VCardText>
      <VCardActions>
        <VContainer class="pa-0 d-flex justify-center" fluid>
          <div class="text-caption">
            Copyright ©
            <a class="d-inline-block" target="_blank" :href="$store.state.app.package.author.url">
              {{ $store.state.app.package.author.name }}
            </a>
            {{ new Date().getFullYear() }}
            (
            <a class="d-inline-block" :href="`mailto:${$store.state.app.package.author.email}`">
              Contact us
            </a>
            )
          </div>
        </VContainer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';

export default Vue.extend({
  name: 'AppAboutDialog',
  mixins: [ExtensionsMixin],
  props: {
    value: { type: Boolean, default: false },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
});
</script>
