import Vue from 'vue';

function isValueEmpty(value) {
  return _.isString(value) ? value.trim().length === 0 : _.isNil(value);
}

export default Vue.extend({
  name: 'InputRulesMixin',
  data: () => ({
    inputRules: {
      required(value) {
        return isValueEmpty(value) ? 'Required' : true;
      },
      email(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://bit.ly/2OaHDLK
        const pattern =
          '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\' +
          's@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,' +
          '3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
        if (!new RegExp(pattern).test(value.trim())) return 'Invalid email format';
        return true;
      },
      password(value) {
        if (_.isString(value)) {
          if (!/^[\x20-\x7e]+$/.test(value))
            return 'Only use 0~9, A~Z, a~z and common punctuation characters';
          if (value.length < 6) return 'Use 6 characters or more for your password';
        }
        return true;
      },
      url(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://bit.ly/3svZivX
        const pattern =
          '^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
          '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\' +
          '.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d' +
          '|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\u' +
          'ffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u0' +
          '0a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?(?:[/?#]\\S*)?$';
        if (!new RegExp(pattern).test(value.trim())) return 'Invalid URL format';
        return true;
      },
      filename(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://stackoverflow.com/a/53635003
        if (/^(con|prn|aux|nul|((com|lpt)[0-9]))$|(["*/:<>?\\|])|(\.|\s)$/im.test(value.trim()))
          return 'Invalid filename';
        return true;
      },
    },
  }),
});
