<template>
  <VApp class="app">
    <VNavigationDrawer
      v-model="navDrawerOpened"
      app
      clipped
      :permanent="!$vuetify.breakpoint.mobile"
      :touchless="!$vuetify.breakpoint.mobile"
    >
      <VList dense>
        <VListItem v-for="(item, i) in navListItems" :key="i" :to="item.path">
          <VListItemIcon>
            <VIcon>{{ item.icon }}</VIcon>
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle>{{ item.title }}</VListItemTitle>
          </VListItemContent>
        </VListItem>
        <VListGroup v-if="patientListItems" :value="true" prepend-icon="mdi-account-group">
          <template #activator>
            <VListItemContent>
              <VListItemTitle>待診病患({{ $store.state.user.ownPatients.length }})</VListItemTitle>
            </VListItemContent>
          </template>

          <template v-if="$store.state.user.ownPatients.length > 0">
            <!-- :to="item.path" -->
            <VListItem
              v-for="(item, i) in $store.state.user.ownPatients"
              :key="`ownPatients${i}`"
              :to="{ name: 'Diagnosis', params: { medicalOrderNumber: item.medicalOrderNumber } }"
              @click="patientOrder(i)"
            >
              <VListItemIcon>
                <VIcon>eEmdi-clipboard-account</VIcon>
              </VListItemIcon>
              <VListItemContent>
                <VListItemTitle>病歷號：{{ item.medicalOrderNumber }}</VListItemTitle>
              </VListItemContent>
              <VListItemAction>
                <VBtn
                  icon
                  @click.prevent="removeSelectedPatient(item.id)"
                  @mousedown.native.stop
                  @touchend.native.stop
                >
                  <VIcon>mdi-close</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
          </template>
          <template v-else>
            <VListItem disabled>
              <VListItemContent>
                <VListItemTitle>目前沒有病患…</VListItemTitle>
              </VListItemContent>
            </VListItem>
          </template>
        </VListGroup>
        <VListGroup v-if="patientListItems" :value="true" prepend-icon="mdi-account-group">
          <template #activator>
            <VListItemContent>
              <VListItemTitle>
                已看診病患（{{ $store.state.user.pastPatients.length }}）
              </VListItemTitle>
            </VListItemContent>
          </template>
          <template v-if="$store.state.user.pastPatients.length > 0">
            <VListItem
              v-for="(item, i) in $store.state.user.pastPatients"
              :key="`pastPatients${i}`"
              :to="item.path"
            >
              <VListItemIcon>
                <VIcon>mdi-clipboard-account</VIcon>
              </VListItemIcon>
              <VListItemContent>
                <VListItemTitle>病歷號：{{ item.medicalOrderNumber }}</VListItemTitle>
              </VListItemContent>
            </VListItem>
          </template>
          <template v-else>
            <VListItem disabled>
              <VListItemContent>
                <VListItemTitle>目前沒有病患…</VListItemTitle>
              </VListItemContent>
            </VListItem>
          </template>
        </VListGroup>
        <VListGroup
          v-if="$store.state.searchResult.length > 0"
          :value="true"
          prepend-icon="mdi-account-group"
        >
          <template #activator>
            <VListItemContent>
              <VListItemTitle>查詢病患({{ $store.state.searchResult.length }}</VListItemTitle>
            </VListItemContent>
          </template>
          <template v-if="$store.state.searchResult.length > 0">
            <VListItem
              v-for="(item, i) in $store.state.searchResult"
              :key="`seachedMedicalOrderNumber${i}`"
              :to="item.path"
            >
              <VListItemIcon>
                <VIcon>mdi-clipboard-account</VIcon>
              </VListItemIcon>
              <VListItemContent>
                <VListItemTitle>
                  病歷號：{{ item.medicalOrderNumber }} - {{ item.name }}
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
          </template>
        </VListGroup>
      </VList>
      <template #append>
        <VContainer fluid>
          <VRow>
            <VCol>
              <VBtn color="info" block @click="isOpenAddPatientDialog = true">
                <VIcon left>mdi-account-multiple-plus-outline</VIcon>
                新增病患資料
              </VBtn>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VBtn color="warning" block @click="isOpenSearchDialog = true">
                <VIcon left>mdi-clipboard-text-search-outline</VIcon>
                查詢歷史病例
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </template>
    </VNavigationDrawer>
    <!-- FIXME: `v-app-bar` overflowed in report page (caused by `print-icon-button`) -->
    <!--        in Firefox Mobile, temporarily fixed by adding `style="width: 100vw"` -->
    <VAppBar app clipped-left color="primary" dark dense style="width: 100vw">
      <template v-if="isCurrentPathInNavListItems">
        <VAppBarNavIcon
          v-if="$vuetify.breakpoint.mobile"
          @click.stop="navDrawerOpened = !navDrawerOpened"
        />
        <VIcon v-else class="ml-n3" style="width: 48px; height: 48px">
          <!-- mdi-{{ $store.state.app.icon }} -->
          mdi-sprout
        </VIcon>
      </template>
      <VBtn v-else icon @click="$router.back()">
        <VIcon>mdi-arrow-left</VIcon>
      </VBtn>
      <VToolbarTitle class="pl-0 text-md-h5 text-subtitle-1">
        {{ $store.state.app.name }}
      </VToolbarTitle>
      <VSpacer />
      <template v-if="!requesting && !isUnderMaintenance">
        <VMenu
          v-if="_.isPlainObject($store.state.profile) && $store.state.signed"
          max-width="240px"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <VBtn
              text
              class="ml-1 pa-1"
              min-width="0"
              height="48"
              :style="{ borderRadius: `${$vuetify.breakpoint.mobile ? 24 : 16}px` }"
              v-bind="attrs"
              v-on="on"
            >
              <VAvatar class="ma-1" size="32">
                <!-- 
                  <img v-if="$store.state.profile.photoUrl" :src="$store.state.profile.photoUrl" />
                  <img v-else src="@/assets/images/grey_silhouette.png" /> 
                -->
                <VImg
                  :src="$store.state.profile.photoUrl"
                  :lazy-src="lazyPhotoURL"
                  style="background-color: #e5e5e5"
                />
              </VAvatar>
              <span
                v-if="!$vuetify.breakpoint.mobile"
                class="ma-1 subtitle-1"
                style="text-transform: none"
              >
                {{ $store.state.profile.name }}
              </span>
            </VBtn>
          </template>
          <VCard>
            <VList>
              <VListItem>
                <VListItemAvatar>
                  <!-- 
                    <img v-if="$store.state.profile.photoUrl" :src="$store.state.profile.photoUrl" />
                    <img v-else src="@/assets/images/grey_silhouette.png" /> 
                  -->
                  <VImg
                    :src="$store.state.profile.photoUrl"
                    :lazy-src="lazyPhotoURL"
                    style="background-color: #e5e5e5"
                  />
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle>{{ $store.state.profile.name }}</VListItemTitle>
                  <VListItemSubtitle>{{ $store.state.profile.email }}</VListItemSubtitle>
                </VListItemContent>
                <VListItemAction>
                  <VBtn icon @click="msgPop('此功能尚未開放')">
                    <VIcon>mdi-square-edit-outline</VIcon>
                  </VBtn>
                </VListItemAction>
              </VListItem>
            </VList>
            <VDivider />
            <VCardActions>
              <VBtn color="pink" outlined text block @click="signOut()">
                <VIcon left>mdi-logout-variant</VIcon>
                登出
              </VBtn>
            </VCardActions>
          </VCard>
        </VMenu>
      </template>
      <VMenu v-model="appMenuIsOpened" left offset-y transition="slide-y-transition">
        <template #activator="{ on, attrs }">
          <VBtn v-bind="attrs" icon :small="$vuetify.breakpoint.mobile" v-on="on">
            <VIcon>mdi-dots-vertical</VIcon>
          </VBtn>
        </template>
        <VList dense>
          <VListItem @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <VListItemIcon>
              <VIcon>mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}</VIcon>
            </VListItemIcon>
            <VListItemTitle>
              {{ `主題：${$vuetify.theme.dark ? '暗' : '亮'}` }}
            </VListItemTitle>
          </VListItem>
          <AppAboutDialog
            v-model="aboutDialogIsOpened"
            @input="$event ? null : (appMenuIsOpened = false)"
          >
            <template #activator="{ on, attrs }">
              <VListItem v-bind="attrs" v-on="on">
                <VListItemIcon>
                  <VIcon>mdi-information</VIcon>
                </VListItemIcon>
                <VListItemTitle>關於</VListItemTitle>
              </VListItem>
            </template>
          </AppAboutDialog>
        </VList>
      </VMenu>
    </VAppBar>
    <VMain>
      <VFadeTransition mode="out-in">
        <RouterView v-if="!requesting && !isUnderMaintenance" style="height: calc(100vh - 48px)" />
      </VFadeTransition>
      <AppAddPatientDialog v-model="isOpenAddPatientDialog" />
      <AppSearchDialog v-model="isOpenSearchDialog" />
    </VMain>
    <AppSignInRegisterDialog v-model="signInRegisterDialogIsOpened" />
    <MessageSnackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
    <VFadeTransition>
      <VOverlay v-if="requesting || isUnderMaintenance" z-index="2147483647">
        <VProgressCircular v-if="requesting" indeterminate size="64" />
        <span v-else class="text-h3">The service is under maintenance now…</span>
      </VOverlay>
    </VFadeTransition>
  </VApp>
</template>

<script>
import Vue from 'vue';

import MessageSnackbar, { MessageSnackbarType } from '@/mixins/MessageSnackbarMixin';
import AppAboutDialog from '@/components/App/AppAboutDialog';
import AppAddPatientDialog from '@/components/App/AppAddPatientDialog';
import AppSearchDialog from '@/components/App/AppSearchDialog';
import AppSignInRegisterDialog from '@/components/App/AppSignInRegisterDialog';
import Cookies from 'js-cookie';
import { md5 } from 'hash-wasm';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';

import { delay } from '@/utils';

export default Vue.extend({
  name: 'App',
  components: {
    AppAboutDialog,
    AppSignInRegisterDialog,
    AppAddPatientDialog,
    AppSearchDialog,
  },
  mixins: [ExtensionsMixin, MessageSnackbar],
  data: () => ({
    navListItems: [
      {
        title: '首頁',
        path: '/',
        icon: 'mdi-home',
      },
    ],
    navDrawerOpened: false,
    appMenuIsOpened: false,
    aboutDialogIsOpened: false,
    signInRegisterDialogIsOpened: false, // Use Cookies
    menuOpened: false,
    aboutDialogOpened: false,
    requesting: false,
    signedIn: false,
    isOpenAddPatientDialog: false,
    isOpenSearchDialog: false,
    signingIn: false,
  }),
  computed: {
    isUnderMaintenance() {
      return (
        this.$store.state.globalOptions?.isUnderMaintenance &&
        process.env.NODE_ENV !== 'development'
      );
    },
    photoURL() {
      return this.getAvatarURL(40);
    },
    lazyPhotoURL() {
      return this.getAvatarURL(8);
    },
    isCurrentPathInNavListItems() {
      // TODO - dummy data
      const paths = this.navListItems.map((item) => item.path);
      if (this.$store.state.user) {
        paths.push(...Object.values(this.$store.state.patientInfo).map((item) => item.path));
      }
      return paths.includes(this.$route.path);
    },
    patientListItems() {
      const patientListItems = this.$store.state.patientInfo.filter(
        (item) => item.selected === false,
      );
      return patientListItems;
    },
  },
  async created() {
    // Use Cookies and restore user's state
    const accessToken = Cookies.get('JWTToken-access');
    const refreshToken = Cookies.get('JWTToken-refresh');
    if (accessToken !== 'undefined' && refreshToken !== 'undefined') {
      this.$store.commit('updateToken', {
        access: accessToken,
        refresh: refreshToken,
      });
      await this.$store.dispatch('fetchPatientInfo');
      this.signInRegisterDialogIsOpened = false;
      this.$store.commit('sign', {});
      this.$store.state.profile.name = 'Demo';
      this.$store.state.profile.email = 'demo@demo.com';
      const { protocol } = window.location;
      const hashed = await md5('demo@demo.com');
      this.$store.state.profile.photoUrl = `${protocol}//www.gravatar.com/avatar/${hashed}?d=identicon&r=g`;
    } else {
      this.signInRegisterDialogIsOpened = true;
    }

    this.requesting = true;
    this.signingIn = !this.$store.state.signed;
    if (this.$store.state.signed) {
      await this.$store.dispatch('bindUser', this.$store.state.profile.uid);
    }
    // this.signInRegisterDialogIsOpened = !_.isPlainObject(this.$store.state.profile);
    this.requesting = false;
  },
  methods: {
    patientOrder(order) {
      this.$store.state.patient = order;
    },
    removeSelectedPatient(pid) {
      console.log(`[updated] ${pid}`);
      this.$store.commit('removeOwnPatient', pid);
      // const updateParameters = { key: pid, updateSelected: { selected: true } };
      // await this.$store.dispatch('updatePatientInfo', updateParameters);
      // await this.$store.dispatch('fetchPatientInfo');
    },
    msgPop(str) {
      this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, str, 'close');
    },
    getAvatarURL(size) {
      const { photoURL } = this.$store.state.profile;
      // if (photoURL.match(/^(https?:\/\/)www\.gravatar\.com\/avatar\//i))
      return `${photoURL}&s=${Math.round(size * window.devicePixelRatio)}`;
      // return photoURL;
    },
    async signOut() {
      this.requesting = true;
      this.$store.dispatch('unbindUser', this.$store.state.profile.uid);
      this.$store.dispatch('unbindWaitingPatient');
      this.$store.dispatch('unbindDiagnosisInfo', this.$store.state.diagnosisId);
      // TODO - do backend signout here with catch error
      await delay(1000);
      this.$store.commit('sign', null);
      this.$store.commit('destroyToken');
      this.signInRegisterDialogIsOpened = true;
      this.requesting = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
