<template>
  <div class="home">
    <!-- TODO -->
    <VCard class="mx-auto">
      <VList>
        <VContainer fluid>
          <VRow>
            <VCol>
              <VListItem>
                <VListItemAvatar size="100">
                  <VImg
                    v-if="$store.state.profile.photoUrl"
                    :aspect-ratio="16 / 9"
                    contain
                    darkd
                    lazy-src
                    :src="$store.state.profile.photoUrl"
                    alt="頭像"
                  />
                  <VImg
                    v-else
                    :aspect-ratio="16 / 9"
                    contain
                    darkd
                    lazy-src
                    src="@/assets/images/grey_silhouette"
                    alt="頭像"
                  />
                </VListItemAvatar>
                <VListItemContent class="px-0 mr-n12">
                  <VCardTitle class="text-h">{{ $store.state.profile.name }}</VCardTitle>
                  <VCardSubtitle>{{ $store.state.profile.email }}</VCardSubtitle>
                </VListItemContent>
                <VListItemAction class="px-0 mx-0">
                  <VBtn icon @click="msgPop('此功能尚未開放')">
                    <VIcon>mdi-square-edit-outline</VIcon>
                  </VBtn>
                </VListItemAction>
              </VListItem>
            </VCol>
          </VRow>
        </VContainer>
      </VList>
      <VDivider />
      <VCardText class="justify-center">
        <VCardTitle class="justify-center text-h5">待診病例</VCardTitle>
        <VDataTable
          v-model="selected"
          :items="patientListItems"
          mobile-breakpoint
          multi-sort
          show-select
          dense
          :headers="headers"
          item-key="medicalOrderNumber"
          class="elevation-1"
        >
          <template #[`item.registerTime`]="{ item }">
            {{ item.registerTimeString }}
          </template>
        </VDataTable>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn class="success" :disabled="selected.length === 0" type="submit" @click="submit()">
          確認
        </VBtn>
      </VCardActions>
    </VCard>
    <VOverlay v-model="requesting">
      <VProgressCircular indeterminate size="64" />
    </VOverlay>
    <VFooter dark padless fixed>
      <VContainer class="pa-0 d-flex justify-center" fluid dark>
        <div class="text-caption">
          Copyright ©
          <a class="d-inline-block"><!-- TODO --></a>
          {{ new Date().getFullYear() }}
        </div>
      </VContainer>
    </VFooter>
    <MessageSnackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import MessageSnackbar, { MessageSnackbarType } from '@/mixins/MessageSnackbarMixin';
import ExtensionsMixin from '@/mixins/ExtensionsMixin';

export default Vue.extend({
  name: 'Home',
  mixins: [ExtensionsMixin, MessageSnackbar],
  data: () => ({
    headers: [
      {
        text: '時段',
        value: 'period',
      },
      {
        text: '診號',
        value: 'consultationNo',
      },
      {
        text: '姓名',
        value: 'name',
        align: 'start',
        sortable: false,
      },
      {
        text: '病歷號',
        value: 'medicalOrderNumber',
        sortable: false,
      },
      {
        text: '健保',
        value: 'status',
        sortable: false,
      },
      {
        text: '科別',
        value: 'department',
        sortable: false,
      },
      {
        text: '卡號',
        value: 'cardId',
      },
      {
        text: '掛號時間',
        value: 'registerTimeString',
      },
      {
        text: '欠費',
        value: 'arrears',
      },
      {
        text: '備註',
        value: 'remark',
        sortable: false,
      },
    ],
    selected: [],
    requesting: false,
  }),
  computed: {
    patientListItems() {
      const patientListItems = this.$store.state.patientInfo.filter(
        (item) => item.selected === false,
      );
      patientListItems.forEach((item) => {
        // console.log('item');
        // console.log(item.selected);
        const registerTime = new Date(item.registerTime);
        item.registerTimeString = registerTime.toDateString();
      });
      return patientListItems;
    },
  },
  async created() {
    this.requesting = true;
    await this.$store.dispatch('bindWaitingPatient');
    this.requesting = false;
  },
  methods: {
    msgPop(str) {
      this.$_mixin_MessageSnackbarMixin_show(MessageSnackbarType.error, str, 'close');
    },
    submit() {
      this.selected.forEach((item) => {
        this.$store.state.patientInfo = this.$store.state.patientInfo.filter((i) => i !== item);
        this.$store.commit('addCurrentDiagnosePatient', item);
      });
      this.$store.state.user.ownPatients.forEach((p) => {
        console.log(p.name);
        console.log(p.selected);
      });
      this.selected = [];
    },
  },
});
</script>
