<template>
  <div>
    <VDataTable
      hide-default-footer
      :headers="headers"
      :items="diagnosisData"
      height="calc(100vh - 439px)"
      class="medication-table"
    >
      <template #top>
        <VToolbar flat>
          <VToolbarTitle>開藥</VToolbarTitle>
          <VSpacer />
          <!-- Consultation -->
          <VBtn color="success" dark class="mb-2 mr-2" @click="openConsultMedicine()">
            建議方藥
          </VBtn>
          <!-- Convert to 錢 -->
          <VBtn color="success" dark class="mb-2 mr-2" @click="openConvertMedicine()">
            換算錢數
          </VBtn>
          <!--
            <VDialog v-model="convertMedicineOpened" persistent max-width="1150px">
            <template #activator="{ on, attrs }">
            <VBtn v-bind="attrs" color="success" dark class="mb-2 mr-2" :disabled="true" v-on="on">
            <VIcon>mdi-add</VIcon>
            換算錢數
            </VBtn>
            </template>
            <VCard>
            <VToolbar dense app color="primary">
            <VSpacer />
            <VBtn icon @click="convertMedicineOpened = false">
            <VIcon>mdi-close</VIcon>
            </VBtn>
            </VToolbar>
            </VCard>
            </VDialog> 
          -->
          <!-- Select medicine -->
          <VBtn color="success" dark class="mb-2 mr-2" @click="openSelectMedicine()">新增</VBtn>
        </VToolbar>
      </template>
      <template #[`item.action`]="{ item }">
        <VIcon small class="mr-2" @click="editItem(item)">mdi-pencil</VIcon>
        <VIcon small @click="deleteItem(item)">mdi-delete</VIcon>
      </template>
    </VDataTable>
    <MedicationSelect />
    <MedicationAdd />
    <MedicationDelete />
    <MedicationInfo />
    <MedicationConvert />
    <MedicationConsultation />
  </div>
</template>

<script>
import Vue from 'vue';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';
import InputRulesMixin from '@/mixins/InputRulesMixin';

import componentBus from '@/main';

import MedicationAdd from '@/components/Diagnosis/Medication/MedicationAdd';
import MedicationConsultation from '@/components/Diagnosis/Medication/MedicationConsultation';
import MedicationConvert from '@/components/Diagnosis/Medication/MedicationConvert';
import MedicationDelete from '@/components/Diagnosis/Medication/MedicationDelete';
import MedicationInfo from '@/components/Diagnosis/Medication/MedicationInfo';
import MedicationSelect from '@/components/Diagnosis/Medication/MedicationSelect';

export default Vue.extend({
  name: 'MedicationTable',
  components: {
    MedicationSelect,
    MedicationAdd,
    MedicationDelete,
    MedicationInfo,
    MedicationConvert,
    MedicationConsultation,
  },
  mixins: [ExtensionsMixin, InputRulesMixin],
  data: () => ({
    headers: [
      {
        text: '品項',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: '份量(錢)',
        sortable: false,
        value: 'amount',
      },
      {
        text: '使用種類',
        sortable: false,
        value: 'type',
      },
      {
        text: '備註',
        sortable: false,
        value: 'remarks',
      },
      {
        text: '編輯',
        sortable: false,
        value: 'action',
      },
    ],
    // diagnosisData: [],
    bopomofoSelected: '',
    addMedicineOpened: false,
    deleteMedicineOpened: false,
    medicineDescOpened: false,
    medicineDesc: null,
    selectMedicineOpened: false,
    convertMedicineOpened: false,
    caseConsultOpened: false,
    convMedInfo: {
      single: [],
      compound: [],
    },
    convTotal: {},
  }),
  computed: {
    diagnosisData() {
      return this.getDiagnosisData();
    },
  },
  watch: {
    // addMedicineOpened(val) {
    //   // val || this.close();
    //   console.log(val);
    // },
    // deleteMedicineOpened(val) {
    //   // val || this.closeDelete();
    //   console.log(val);
    // },
    // searchedMedicine(val) {
    //   console.log(val);
    // },
    bopomofoSelected() {
      this.searchedMedicine = null;
    },
    medicineSelectionMode() {
      this.searchedMedicine = null;
    },
  },
  methods: {
    openSelectMedicine() {
      componentBus.$emit('medication-select');
    },
    openConsultMedicine() {
      componentBus.$emit('medication-consult');
    },
    openConvertMedicine() {
      componentBus.$emit('medication-convert');
    },
    isCurrentItemInOptions(arr, item) {
      return arr.indexOf(item) !== -1;
    },
    editItem(item) {
      componentBus.$emit('medication-edit', item);
    },
    deleteItem(item) {
      componentBus.$emit('medication-delete', item);
    },
    getDiagnosisData() {
      const { currentPatientDiagnosis } = this.$store.state;
      const medicationTable = this.$store.state.patientMedicationInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return medicationTable.medication;
    },
  },
});
</script>
