import Vue from 'vue';

import '@/plugins/lodash';
import '@/registerServiceWorker';
import App from '@/App';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import store from '@/plugins/store';
import vuetify from '@/plugins/vuetify';

import '@/styles/global.scss';

Vue.config.productionTip = false;

const componentBus = new Vue();
export { componentBus as default };

// eslint-disable-next-line vue/require-name-property
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
