import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { withAttributes } from 'js-cookie';

const getAPI = axios.create({
  baseURL: 'http://134.208.3.250:8000/', // Django URL
  timeout: 10000,
});

export default getAPI;
