import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex from 'vuex';
import getAPI from '@/axios-api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      name: process.env.VUE_APP_NAME,
      icon: process.env.VUE_APP_ICON,
      themeColor: process.env.VUE_APP_THEME_COLOR,
      package: {
        version: process.env.VUE_APP_PACKAGE_VERSION,
        author: process.env.VUE_APP_PACKAGE_AUTHOR,
        contributors: process.env.VUE_APP_PACKAGE_CONTRIBUTORS,
      },
    },
    signed: false,
    profile: {
      id: null,
      name: null,
      role: null,
      email: null,
      photoUrl: null,
      ownPatients: null,
      pastPatients: null,
    },
    // to open/close diagnosis dialog
    patientInfo: [],
    currentPatientDiagnosisInfo: [],
    tempSymptom: {},
    tempMedication: [],
    medicationStocks: {
      六君子湯: 100,
      六味地黃丸: 100,
      川芎: 100,
      柴胡: 100,
      陳皮: 100,
      苓甘薑味辛夏仁湯: 100,
    },
    searchedPatientByTime: [],
    searchResult: [],
    patientId: '',
    diagnosisId: '77HfRggFC7AuUJEPna0b',
    // patientListItems: [],
    patient: null,
    diagnosisInfo: null,
    patientDiagnosisArray: [],
    globalOptions: {
      isUnderMaintenance: false,
    },
    currentPatientDiagnosis: {}, // <-- currently being dianosed
    defaultImageTable: {},
    patientDiagnosisInfoArray: [], // <-- all patient diagnosis
    patientMedicationInfoArray: [], // <-- all patient medication
    patientImageArray: [], // <-- all patient images
    user: {
      // <-- use this instead of currentPatientDiagnosisArray
      ownPatients: [],
      pastPatients: [],
    },
    accessToken: null,
    refreshToken: null,
  },
  mutations: {
    sign(state, user) {
      state.signed = user;
      state.profile.id = user ? user.id : null;
      state.profile.name = user ? user.displayName : null;
      state.profile.role = user ? user.role : null;
      state.profile.email = user ? user.email : null;
      state.profile.photoUrl = user ? user.photoURL : null;
      state.profile.pastPatients = user ? user.pastPatients : null;
      state.profile.ownPatients = user ? user.ownPatients : null;
    },
    patientId(state, patientId) {
      state.patientId = patientId;
    },
    patientTempStore(state, patientDiagnosis) {
      if (patientDiagnosis.idx === -1) {
        state.patientDiagnosisArray.push(patientDiagnosis.data);
      } else {
        state.patientDiagnosisArray[patientDiagnosis.idx] = patientDiagnosis.data;
      }
    },
    removePatientTempStore(state, idx) {
      state.patientDiagnosisArray.splice(idx, 1);
    },
    patientInfoData(state, data) {
      state.patientInfo = data;
    },
    patientDiagnosisInfoData(state, data) {
      state.currentPatientDiagnosisInfo = data;
    },
    patientMedicationData(state, data) {
      state.tempMedication = data;
    },
    patientSymptomData(state, data) {
      state.tempSymptom = { ...data };
    },
    resetTempStore(state) {
      state.currentPatientDiagnosisInfo = [];
      state.tempMedication = [];
      Object.getOwnPropertyNames(state.tempSymptom).forEach((prop) => {
        delete state.tempSymptom[prop];
      });
    },
    searchedPatientData(state, data) {
      state.searchedPatientByTime = data;
    },
    filterResult(state, filterInfo) {
      state.searchResult = state.searchedPatientByTime.filter(
        (patient) =>
          patient.name.includes(filterInfo.name) &&
          String(patient.medicalOrderNumber).includes(filterInfo.medicalOrderNumber),
      );
    },
    clearSearchResult(state) {
      state.searchedPatientByTime = [];
      state.searchResult = [];
    },
    checkingData() {
      // return;
    },
    updatePatientInfo(state, payload) {
      const patientIndex = state.patientInfo.findIndex(
        (item) => item.medicalOrderNumber === payload.medicalOrderNumber,
      );
      const newData = [...state.patientInfo];
      newData[patientIndex] = payload;
      state.patientInfo = newData;
    },
    updatePatientDiagnosisInfo(state, payload) {
      const diagnosisIndex = state.patientDiagnosisArray.findIndex(
        (item) => item.patientId === payload.patient,
      );
      state.patientDiagnosisArray[diagnosisIndex] = payload.diagnosisData;
    },
    createNewPatient(state, patient) {
      state.patientInfo.push(patient);
    },
    addCurrentDiagnosePatient(state, patient) {
      // patient.selected = true;
      state.user.ownPatients.push(patient);
    },
    setCurrentDiagnosePatient(state, patient) {
      state.currentPatientDiagnosis = patient;
    },
    updatePatientSymptomTable() {
      // const currPatient = state.user.ownPatients.find(
      //   (patient) => patient.id === patientId,
      // );
      // console.log(currPatient, simpletable);
    },
    createImages(state, patientId) {
      // TODO - THIS SHIT
      const newImages = {
        patientId,
        yanImages: [
          // {
          //   diagnosisId: -1,
          //   image: null,
          //   date: new Date('2011', '04' - 1, '11', '11', '51', '00'),
          //   remark: '眼 example',
          // },
        ],
        sheImages: [
          // {
          //   diagnosisId: -1,
          //   image: null,
          //   date: new Date('2011', '04' - 1, '11', '11', '51', '00'),
          //   remark: '舌 example',
          // },
        ],
      };
      state.patientImageArray.push(newImages);
      // const yanImages = {
      //   diagnosisId: 1,
      //   image: null,
      //   date: new Date('2011', '04' - 1, '11', '11', '51', '00'),
      //   remark: '眼 description 1',
      // };
    },
    createDiagnoseInfo(state, patientId) {
      const newDiagnoseInfo = {
        patientId,
        diagnosis: {
          wen: {
            name: '問',
            data: [],
          },
          qie: {
            name: '切',
            data: [],
          },
          wang: {
            name: '望',
            data: [],
          },
          yan: {
            name: '眼',
            data: [],
          },
          she: {
            name: '舌',
            data: [],
          },
        },
      };
      state.patientDiagnosisInfoArray.push(newDiagnoseInfo);
      if (!state.patientImageArray.find((item) => item.patientId === patientId)) {
        this.commit('createImages', patientId);
      }
    },
    createMedicationInfo(state, patientId) {
      const newMedicationInfo = {
        patientId,
        medication: [],
      };
      state.patientMedicationInfoArray.push(newMedicationInfo);
    },
    addPastPatient(state, patient) {
      if (!state.user.pastPatients.includes(patient)) {
        state.user.pastPatients.push(patient);
        return;
      }
      state.user.pastPatients = state.user.pastPatients.filter((p) => p.id !== patient.id);
      state.user.pastPatients.push(patient);
    },
    removeOwnPatient(state, pid) {
      state.user.ownPatients = state.user.ownPatients.filter((p) => p.id !== pid);
    },
    updateToken(state, { access, refresh }) {
      state.accessToken = access;
      state.refreshToken = refresh;
      Cookies.set('JWTToken-access', access);
      Cookies.set('JWTToken-refresh', refresh);
    },
    destroyToken(state) {
      state.accessToken = null;
      state.refreshToken = null;
      Cookies.remove('JWTToken-access');
      Cookies.remove('JWTToken-refresh');
    },
  },
  actions: {
    bindUser() {
      // TODO
    },
    unbindUser() {
      // TODO
    },
    bindWaitingPatient() {
      // const { ownPatients } = state.profile;
      // let patientQuery = '/patients?';
      // ownPatients.forEach((patientID) => {
      //   patientQuery += `id=${patientID}&`;
      // });
      // patientQuery = patientQuery.slice(0, -1);
      // await getAPI // update user Ownpatient
      // .put(`/users/${state.profile.id}`, state.profile)
      // .then((res) => {
      //   console.log(res.status);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    bindDiagnosisInfo() {
      // TODO
    },
    unbindDiagnosisInfo() {
      // TODO
    },
    bindPatient() {
      // TODO
    },
    subscribeToPatientInfoChange() {
      // TODO
    },
    subscribeToDiagnosisInfoChange() {
      // TODO
    },
    async fetchPatientInfo({ commit, state }) {
      await getAPI('/patients/', {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
        },
      })
        .then((res) => {
          commit('patientInfoData', res.data);
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async postDiagnosisInfo({ commit, dispatch, state }) {
      const index = state.patientDiagnosisInfoArray.findIndex(
        (item) => item.patientId === state.currentPatientDiagnosis.id,
      );
      console.log(state.currentPatientDiagnosis.id);
      console.log(state.patientDiagnosisInfoArray[index]);
      console.log(
        JSON.stringify({
          patient: state.currentPatientDiagnosis.id,
          diagnosisData: state.patientDiagnosisInfoArray[index],
        }),
      );
      await getAPI
        .post(
          '/patients/diagnosis/info/',
          {
            patient: state.currentPatientDiagnosis.id,
            diagnosisData: state.patientDiagnosisInfoArray[index],
          },
          {
            headers: {
              Authorization: `Bearer ${state.accessToken}`,
            },
          },
        )
        .then((res) => {
          const diagnosisId = res.data.id;
          commit('updatePatientDiagnosisInfo', res.data.diagnosisData);
          dispatch('updatePatientInfo'); // TODO
          dispatch('updatePatientImages', diagnosisId);
        })
        .catch((err) => {
          console.log(err);
        });
      // commit('updatePatientInfo', );
    },
    async createNewPatient({ commit, state }, patient) {
      // console.log(JSON.stringify(patient));
      // console.table(patient);
      await getAPI // Add patient
        .post('/patients/', patient, {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .then((res) => {
          commit('createNewPatient', res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updatePatientInfo({ state }) {
      // Only updating current patient
      const patientId = state.currentPatientDiagnosis.id;
      const currentPatient = state.currentPatientDiagnosis;
      currentPatient.registerTime = new Date(currentPatient.registerTime).toISOString();
      console.log(JSON.stringify(currentPatient));
      await getAPI
        .put(`/patients/${patientId}/`, currentPatient, {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        })
        .catch((err) => console.log(err));
    },
    async updatePatientImages({ state }, diagnosisId) {
      const patientId = state.currentPatientDiagnosis.id;
      const imgIndex = state.patientImageArray.findIndex((item) => item.patientId === patientId);
      // const patientImgs = { yan: [], she: [] };
      state.patientImageArray[imgIndex].yanImages.forEach(async (item) => {
        const url = 'http://134.208.3.250:8000/patients/diagnosis/images/yan/';
        const axConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.accessToken}`,
          },
        };
        const fd = new FormData();
        item.date = new Date(item.date).toISOString();
        fd.append('image', item.file);
        fd.append('diagnosis', diagnosisId);
        fd.append('date', item.date);
        fd.append('remark', item.remark);
        try {
          const res = await getAPI.post(url, fd, axConfig);
          console.log(res);
        } catch (err) {
          console.log(err);
        }
      });
      state.patientImageArray[imgIndex].sheImages.forEach(async (item) => {
        const url = 'http://134.208.3.250:8000/patients/diagnosis/images/she/';
        const axConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.accessToken}`,
          },
        };
        const fd = new FormData();
        item.date = new Date(item.date).toISOString();
        fd.append('image', item.file);
        fd.append('diagnosis', diagnosisId);
        fd.append('date', item.date);
        fd.append('remark', item.remark);
        try {
          const res = await getAPI.post(url, fd, axConfig);
          console.log(res);
        } catch (err) {
          console.log(err);
        }
      });
      // await getAPI.post('/patients/');
    },
    async fetchPatientDiagnosisInfoData() {
      // TODO
    },
    async fetchPatientMedication() {
      // TODO
    },
    async fetchPatientSymptom() {
      // TODO
    },
    async createPatientDiagnosisInfo() {
      // TODO
    },
    async updatePatientDiagnosisInfo() {
      // TODO
    },
    resetTempStore({ commit }) {
      commit('resetTempStore');
    },
    async searchPatientInfoData() {
      // TODO
    },
    filterResultData({ commit }, filterInfo) {
      commit('filterResult', filterInfo);
    },
    clearSearchResultData({ commit }) {
      commit('clearSearchResult');
    },
  },
  modules: {},
});
