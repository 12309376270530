import Vue from 'vue';

import * as extensions from '../extensions';

export default Vue.extend({
  name: 'ExtensionsMixin',
  data: () => {
    const data = {};
    Reflect.ownKeys(extensions).forEach((key) => {
      if (_.isString(key) && key !== '__esModule') data[`${key}$`] = extensions[key];
    });
    return data;
  },
});
