<template>
  <VSnackbar
    class="message-snackbar"
    :timeout="timeout"
    :value="value"
    @input="$emit('input', $event)"
  >
    {{ message }}
    <template #action="{ attrs }">
      <VBtn
        v-if="action !== null"
        v-bind="attrs"
        text
        :color="`${type} lighten-2`.trim()"
        @click="
          $emit('result', action.toLowerCase());
          $emit('input', false);
        "
      >
        {{ action }}
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MessageSnackbar',
  props: {
    value: { type: Boolean, default: true },
    type: { type: String, default: '' },
    message: { type: String, default: '' },
    action: { type: String, default: null },
    timeout: { type: [Number, String], default: null },
  },
});
</script>
