<template class="medication-select">
  <div>
    <VDialog v-model="dialogOpened" persistent max-width="1150px">
      <VCard>
        <VToolbar dense color="primary">
          <VSpacer />
          <VBtn icon @click="dialogOpened = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VContainer>
          <VRow class="pa-3">
            <VCol class="col-2">
              <VRadioGroup v-model="medicineSelectionMode" row>
                <VRadio
                  label="單方"
                  value="單方"
                  :disabled="selectMedicineScreen !== 'search'"
                ></VRadio>
                <VRadio
                  label="複方"
                  value="複方"
                  :disabled="selectMedicineScreen !== 'search'"
                ></VRadio>
              </VRadioGroup>
              <VRow>
                <VCol v-for="i in 2" :key="i" class="ma-0 pa-0">
                  <div v-for="ii in bopomofoArray.length / 2" :key="ii">
                    <VBtn
                      v-if="bopomofoArray[ii + (i - 1) * (bopomofoArray.length / 2)]"
                      :disabled="selectMedicineScreen !== 'search'"
                      @click="newBopomofo(i, ii)"
                    >
                      {{ bopomofoArray[ii + (i - 1) * (bopomofoArray.length / 2)] }}
                    </VBtn>
                  </div>
                </VCol>
              </VRow>
            </VCol>
            <VDivider vertical />
            <VCol class="col-10">
              <VRow class="py-1 px-3" grow>
                <VIcon class="mx-2">mdi-magnify</VIcon>
                <VAutocomplete
                  v-model="searchedMedicine"
                  :items="dummyMedicineNames"
                  dense
                  label="Search medicine"
                  class="mr-3"
                  :disabled="selectMedicineScreen !== 'search'"
                ></VAutocomplete>
                <VSpacer></VSpacer>
                <VBtn
                  color="primary"
                  @click="
                    selectMedicineScreen = selectMedicineScreen === 'search' ? 'listed' : 'search'
                  "
                >
                  <div v-if="selectMedicineScreen === 'search'">Selected Medicine</div>
                  <div v-else>Search Medicine</div>
                </VBtn>
              </VRow>
              <VDivider />
              <VRow>
                <VCol>
                  <VCard height="550" style="overflow: auto" class="ma-0 pa-0" elevation="0">
                    <div v-if="selectMedicineScreen === 'search'">
                      <VCard
                        v-for="i in dummyMedicineCards"
                        :key="i.title"
                        class="pa-2"
                        elevation="3"
                        outlined
                        max-width="98%"
                      >
                        <VRow class="align-center">
                          <VCol cols="11">
                            <VCardTitle>
                              {{ i.title }}
                              <VIcon class="ml-2" size="15" @click="medicineDescOpen(i)">
                                mdi-information-outline
                              </VIcon>
                            </VCardTitle>
                            <VCardText>{{ i.desc }}</VCardText>
                          </VCol>
                          <VCol class="align-center pa-1" cols="1">
                            <VBtn text elevation="0" class="mr-5" @click="addFromSearch(i)">
                              <VIcon>mdi-plus</VIcon>
                            </VBtn>
                          </VCol>
                        </VRow>
                      </VCard>
                    </div>
                    <div v-else>
                      <VDataTable
                        hide-default-footer
                        :headers="headers"
                        :items="diagnosisData"
                        height="calc(100vh - 439px)"
                        class="medication-table"
                      >
                        <template #[`item.action`]="{ item }">
                          <VIcon small class="mr-2" @click="editItem(item)">mdi-pencil</VIcon>
                          <VIcon small @click="deleteItem(item)">mdi-delete</VIcon>
                        </template>
                      </VDataTable>
                    </div>
                  </VCard>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VContainer>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationSelect',
  data: () => ({
    dialogOpened: false,
    medicineSelectionMode: '',
    selectMedicineScreen: 'search',
    searchedMedicine: null,
    dummyMedicines: [],
    bopomofoArray: [
      'ㄅ',
      'ㄆ',
      'ㄇ',
      'ㄈ',
      'ㄉ',
      'ㄊ',
      'ㄋ',
      'ㄌ',
      'ㄍ',
      'ㄎ',
      'ㄏ',
      'ㄐ',
      'ㄑ',
      'ㄒ',
      'ㄓ',
      'ㄔ',
      'ㄕ',
      'ㄖ',
      'ㄗ',
      'ㄘ',
      'ㄙ',
      'ㄚ',
      // 'ㄛ',
      'ㄜ',
      // 'ㄝ',
      'ㄞ',
      // 'ㄟ',
      // 'ㄠ',
      'ㄡ',
      'ㄢ',
      // 'ㄣ',
      // 'ㄤ',
      // 'ㄥ',
      'ㄦ',
      'ㄧ',
      'ㄨ',
      'ㄩ',
      // 'ㄪ',
      // 'ㄫ',
      // 'ㄬ',
      // 'ㄭ',
    ],
    headers: [
      {
        text: '品項',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: '份量(錢)',
        sortable: false,
        value: 'amount',
      },
      {
        text: '使用種類',
        sortable: false,
        value: 'type',
      },
      {
        text: '備註',
        sortable: false,
        value: 'remarks',
      },
      {
        text: '編輯',
        sortable: false,
        value: 'action',
      },
    ],
  }),
  computed: {
    dummyMedicineNames() {
      const medicineNames = [];
      this.dummyMedicines.forEach((medicine) => medicineNames.push(medicine.title));
      return medicineNames;
    },
    dummyMedicineCards() {
      if (this.searchedMedicine === null) return this.dummyMedicines;
      const table = [
        {
          title: this.searchedMedicine,
          desc: `Medicine example for ${this.bopomofoSelected} in ${this.medicineSelectionMode}`,
        },
      ];
      return table;
    },
    diagnosisData() {
      return this.getMedicationData();
    },
  },
  watch: {
    medicineSelectionMode() {
      this.bopomofoSelected = '';
      this.dummyMedicines = [];
    },
  },
  created() {
    componentBus.$on('medication-select', () => {
      this.dialogOpened = true;
    });
  },
  methods: {
    newBopomofo(i, ii) {
      this.bopomofoSelected = this.bopomofoArray[ii + (i - 1) * (this.bopomofoArray.length / 2)];
      if (this.bopomofoSelected === '' || this.medicineSelectionMode === '')
        this.dummyMedicines = [];
      const table = [];
      [...Array(20).keys()].forEach((dum) => {
        table.push({
          type: this.medicineSelectionMode,
          title: `${this.bopomofoSelected} ${this.medicineSelectionMode} ${dum}`,
          desc: `Medicine example for ${this.bopomofoSelected} in ${this.medicineSelectionMode}`,
        });
      });
      this.dummyMedicines = table;
    },
    getMedicationData() {
      const { currentPatientDiagnosis } = this.$store.state;
      const medicationTable = this.$store.state.patientMedicationInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return medicationTable.medication;
    },
    addFromSearch(item) {
      componentBus.$emit('medication-add', item);
    },
    editItem(item) {
      componentBus.$emit('medication-edit', item);
    },
    deleteItem(item) {
      componentBus.$emit('medication-delete', item);
    },
    medicineDescOpen(item) {
      componentBus.$emit('medication-info', item);
    },
  },
});
</script>
