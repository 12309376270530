<template>
  <VDialog v-model="dialogOpened" max-width="500px">
    <VCard>
      <VCardTitle class="headline">確定要刪除該項目?</VCardTitle>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn color="blue darken-1" text @click="closeDelete()">取消</VBtn>
        <VBtn color="blue darken-1" text @click="deleteItemConfirm()">確認</VBtn>
        <VSpacer></VSpacer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationDelete',
  data: () => ({
    dialogOpened: false,
    editedItem: {
      name: '',
      usage: '',
      type: '',
      remarks: '',
      amount: 0,
      ingredient: {},
    },
    defaultItem: {
      name: '',
      usage: '',
      type: '',
      remarks: '',
      amount: 0,
      ingredient: {},
    },
    editedIndex: -1,
  }),
  computed: {},
  created() {
    componentBus.$on('medication-delete', (item) => {
      const diagnosisData = this.getDiagnosisData();
      this.editedIndex = diagnosisData.indexOf(item);
      this.editedItem = this.$delete({}, item);
      this.dialogOpened = true;
    });
  },
  methods: {
    closeDelete() {
      this.dialogOpened = false;
      this.$nextTick().then(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      const diagnosisData = this.getDiagnosisData();
      console.log('deleteItemConfirm');
      const deleteItem = diagnosisData[this.editedIndex];
      this.$store.state.medicationStocks[deleteItem.name] =
        Number(this.$store.state.medicationStocks[deleteItem.name]) + Number(deleteItem.amount);
      diagnosisData.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    getDiagnosisData() {
      const { currentPatientDiagnosis } = this.$store.state;
      const medicationTable = this.$store.state.patientMedicationInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return medicationTable.medication;
    },
  },
});
</script>
