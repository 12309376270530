<template>
  <VDialog
    v-model="dialogOpened"
    class="patient-observation-dialog"
    persistent
    max-width="500px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VToolbar dense dark class="primary">
        <VToolbarTitle>望診</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="close()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <!-- TODO - selected items here -->
      <VSimpleTable dense fixed-header>
        <thead>
          <th>症狀選項</th>
          <th>內容</th>
        </thead>
        <tbody>
          <tr v-for="item in addItem" :key="item.selected">
            <td class="text-center">{{ item.label }}</td>
            <td class="text-center">{{ item.selected }}</td>
          </tr>
        </tbody>
      </VSimpleTable>
      <VDivider />
      <!-- TODO - Contents here -->
      <VCardText class="mt-4">
        <VContainer fluid>
          <VRow>
            <VCol v-for="category in categories" :key="category.label">
              <h2>{{ category.label }}</h2>
              <VRadioGroup v-model="category.selected">
                <VRadio v-for="op in category.content" :key="op" :label="op" :value="op"></VRadio>
              </VRadioGroup>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <!-- TODO - buttons here -->
        <VBtn text color="red accent-2" @click="close()">取消</VBtn>
        <VSpacer />
        <VBtn text color="info" @click="submit()">完成</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';

export default Vue.extend({
  name: 'PatientObservationDialog',
  mixins: [ExtensionsMixin],
  data: () => ({
    categories: {
      望診: {
        label: '望診',
        selected: '',
        content: ['瘦弱無力', '瘦但有力', '中等', '虛胖', '壯碩'],
      },
      膚色: {
        label: '膚色',
        selected: '',
        content: ['黃皮膚', '黑皮膚', '白皮膚', '深黑色', '淺黑色'],
      },
      膚質: {
        label: '膚質',
        selected: '',
        content: ['乾性', '中性', '油性', '混和性', '敏感性'],
      },
    },
    requesting: false,
    dialogOpened: false,
  }),
  computed: {
    addItem() {
      const items = [];
      Object.keys(this.categories).forEach((key) => {
        if (this.categories[key].selected) {
          items.push({
            label: this.categories[key].label,
            selected: this.categories[key].selected,
          });
        }
      });
      return items;
    },
    currentContentOptions() {
      if (this.addItem.option) {
        return this.item[this.addItem.option];
      }
      if (this.addItem.option === '' && this.item) {
        return Object.entries(this.item)[0][1];
      }
      return [];
    },
  },
  created() {
    componentBus.$on('adjust-content', (name) => {
      if (name === '望') {
        const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
          (item) => item.patientId === this.$store.state.currentPatientDiagnosis.id,
        );
        Object.keys(this.categories).forEach((categ) => {
          this.categories[categ].selected = '';
        });
        if (symptomTable.diagnosis.wang.data.length.length !== 0) {
          symptomTable.diagnosis.wang.data.forEach((item) => {
            const [a, b] = item;
            this.categories[a].selected = b;
          });
        }
        this.dialogOpened = true;
      }
    });
  },
  methods: {
    submit() {
      const symptomTable = this.getSymptomTable();
      symptomTable.diagnosis.wang.data = [];
      this.addItem.forEach((item) => {
        symptomTable.diagnosis.wang.data.push([item.label, item.selected, '']);
      });
      // TODO - make table update gud
      symptomTable.diagnosis.wang.data.push('.');
      symptomTable.diagnosis.wang.data.pop();
      this.close();
    },
    close() {
      // this.initData();
      this.dialogOpened = false;
      // this.$emit('input', false);
    },
    initData() {
      Object.keys(this.categories).forEach((key) => {
        this.categories[key].selected = '';
      });
    },
    getSymptomTable() {
      const { currentPatientDiagnosis } = this.$store.state;
      const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return symptomTable;
    },
  },
});
</script>

<style scoped>
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 43%;
}
</style>
