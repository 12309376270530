<template>
  <VDialog v-model="dialogOpened" max-width="550px">
    <VCard elevation="1" height="600" style="overflow: auto">
      <h3 class="ml-3">方名 - Example</h3>
      <h3 class="ml-3">來源 - Example</h3>
      <VDivider />
      <VCardText class="pa-3">
        <h1>製法用量</h1>
        <p>
          桂枝去皮9克(3兩) 白芍9克(3兩) 甘草炙6克(2兩) 生薑切9克(3兩) 大棗擘12枚
          右五味，咀三味，以水七升，微火煮取三升，去滓，適寒溫，服一升。服已須臾，啜熱稀粥一升餘，以助藥力，溫服令一時許，遍身漐漐微似有汗者益佳；不可令如水流漓，病必不除。若一服汗出病差，停後服，不必盡劑；若不汗，更服，依前法；又不汗，後服小促其間，半日許令三服盡。若病重者，一日一夜服，周時觀之，服一劑盡，病證猶在者，更作服；若汗不出，乃服至二、三劑。禁生冷、粘滑、肉麵、五辛、酒酪、臭惡等物。
        </p>
        <br />
        <h1>功效</h1>
        <p>解肌祛風，調和營衛。</p>
        <br />
        <h1>主治</h1>
        <p>外感風寒表虛證。頭痛發熱，汗出惡風，鼻鳴乾嘔、苔薄白、脈浮弱或浮緩。</p>
        <br />
        <h1>方義</h1>
        <p>
          本方為仲景群方之首，其配伍嚴謹，發中有補，散中有收，乃古之名方也。方中桂枝辛溫解表，溫經通陽；芍藥酸苦微寒，養血歛陰，又桂芍等量相配，一散一收，既可發汗以散肌表之邪，又可止汗而不留邪；生薑辛溫，既可助桂枝解肌散寒，又可溫胃止嘔，大棗、甘平、既能益氣和中又能補脾生津，薑棗相配可升騰脾胃生發之氣，而調和營衛；炙甘草益氣和中，調和諸藥。諸藥合用，調陰陽和營衛，無論外感病或雜病均可加減使用。
        </p>
        <br />
        <h1>辨證要點</h1>
        <ol>
          <li>外感風寒表虛汗出惡風。</li>
          <li>頭痛發熱。</li>
          <li>鼻鳴乾嘔。</li>
          <li>苔白薄。</li>
          <li>脈浮弱或浮緩。</li>
        </ol>
        <br />
        <h1>加減</h1>
        <ol>
          <li>微喘或氣喘：加厚朴、杏仁。</li>
          <li>頸項痠痛：加葛根。</li>
          <li>陽虛多汗：加附子。</li>
          <li>體弱易感：黃耆。</li>
          <li>肩周圍炎：加薑黃、丹參、當歸。</li>
          <li>腹痛：倍加芍藥。</li>
          <li>惡寒無汗：加麻黃、葛根。</li>
          <li>熱多寒少：加石膏、知母。</li>
        </ol>
        <br />
        <h1>文獻別錄</h1>
        <ol>
          <li>
            解熱、鎮痛、抗炎與鎮靜作用《中成藥研究》1983(3):25，解熱試驗說明，75%桂枝湯能使發熱家兔肛溫降低0.71，皮溫試驗也可使小鼠正常皮膚溫度降低8.13%。鎮痛實驗結果，在120分鐘時，37.5%、75%桂枝湯使小鼠基礎痛閾分別增高64.82%和105.35%；與0.1%嗎啡(20mg/kg)的作用相近；75%桂枝湯對小鼠扭體反應的抑制率為90%。另外，桂枝湯有較強抗甲醛性炎症作用，致炎後1小時，37.5%和84.77%。實驗表明桂枝湯能抑制小鼠自由活動，增強巴比妥類藥物的催眠作用。
          </li>
          <li>
            對巨噬細胞功能的促進作用《福建中醫藥》1983(1):61，本方對小白鼠巨噬細胞功能有明顯促進作用。桂枝湯組的巨噬細胞吞噬率為24.4%、吞噬指數為42.2%；對照組則分別為21.0%、36.4%，(P《
            0.05)有顯著性差異。實驗還參考Ashman、Ellinggon提出的體溫升高巨噬細胞活動能力隨之增強的觀察結果，證實了原方「啜粥溫覆」的科學性。并採用不同給藥時間分組觀察，發現每日服2～3劑或每日連服數日的效果最佳，與仲景「後服小促」、「不愈更作」的記載一致。
          </li>
        </ol>
        <br />
        <h1>注意禁忌</h1>
        <p>凡表實無汗，外感濕邪，平素嗜酒，內有濕熱者，禁用。</p>
        <br />
        <h1>現代應用</h1>
        <p>
          本方調整汗腺功能，促進血液循環，抑制感冒病毒。用於傷風感冒、多汗症、過敏性鼻炎、皮膚病、肩凝、神經痛、冷性腹痛、陰萎、氣喘、虛弱體質之改善。
        </p>
        <br />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationInfo',
  data: () => ({
    dialogOpened: false,
  }),
  created() {
    componentBus.$on('medication-info', (name) => {
      console.log(name);
      this.dialogOpened = true;
    });
  },
});
</script>
