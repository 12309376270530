<template>
  <VDialog v-model="dialogOpened" persistent max-width="1000px">
    <VCard height="800px">
      <VToolbar dense color="primary">
        <VToolbarTitle>換算錢數</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="dialogOpened = false">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VContainer class="ma-0 pa-0">
        <VCol class="ma-0 pa-0">
          <VSheet elevation="20" class="ma-1 pa-3">
            <VRadioGroup v-model="viewMode" row dense>
              <VRadio label="Medicine" value="Medicine"></VRadio>
              <VRadio label="Total" value="Total" @click="convertAllMed()"></VRadio>
            </VRadioGroup>
          </VSheet>
          <VCard style="overflow: auto" class="ma-0 pa-3" elevation="0" height="650px">
            <div v-if="viewMode === 'Medicine'">
              <VCardTitle>單方</VCardTitle>
              <VDivider />
              <div v-if="onlyCompound.length">
                <VCard
                  v-for="med in onlyCompound"
                  :key="med.item.name"
                  elevation="5"
                  color="secondary"
                  class="ma-4 pa-2"
                  dense
                >
                  <VContainer class="mx-2 pa-0">
                    <VCol align="center" justify="center" class="ma-0 pa-0">
                      <VRow align="center" justify="center" class="my-0 mx-3 pa-0">
                        <h3>{{ med.item.name }}</h3>
                        <VSpacer />
                        <p class="my-0 mx-2 pa-0">本品每</p>
                        <VCol cols="1" align="right" class="my-0 mx-2 pa-0">
                          <VTextField
                            v-model="med.item.density"
                            align="center"
                            @change="changeAmount(med.item.density, 'density')"
                          ></VTextField>
                        </VCol>
                        <p class="my-0 mx-2 pa-0" align="left">Gm 中含</p>
                        <VBtn icon @click="medShow(med)">
                          <VIcon>{{ med.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</VIcon>
                        </VBtn>
                      </VRow>
                      <VExpandTransition>
                        <div v-show="med.show">
                          <VDivider />
                          <VRow
                            v-for="index in Object.keys(med.item.ingredient)"
                            :key="index + '_' + med.item.name"
                            align="center"
                            justify="center"
                            class="ma-3 px-2"
                            height="50px"
                          >
                            <VCol cols="2" class="ma-0 pa-0">
                              <p class="body-2">{{ index }}</p>
                            </VCol>
                            <VSpacer />
                            <VCol cols="1" class="ma-0 pa-0" align="right">
                              <VTextField
                                v-model="med.item.ingredient[index]"
                                height="10px"
                                @change="changeAmount(med.item.ingredient[index], index)"
                              ></VTextField>
                            </VCol>
                            <VCol cols="2" class="ma-0 pa-0">
                              <p class="body-2 mx-2" align="left">Gm</p>
                            </VCol>
                          </VRow>
                          <VRow align="center" justify="center" class="ma-3 px-2" height="50px">
                            <VCol cols="2" class="ma-0 pa-0">
                              <p class="body-1">以上生藥製成浸膏</p>
                            </VCol>
                            <VSpacer />
                            <VCol cols="1" class="ma-0 pa-0" align="right">
                              <VTextField
                                v-model="med.item.yield"
                                height="10px"
                                @change="changeAmount(med.item.density, 'yield')"
                              ></VTextField>
                            </VCol>
                            <VCol cols="1" class="ma-0 pa-0">
                              <p class="body-2 mx-2" align="left">Gm</p>
                            </VCol>
                          </VRow>
                        </div>
                      </VExpandTransition>
                    </VCol>
                  </VContainer>
                </VCard>
              </div>
              <div v-else>
                <VCardText>There are no 單方 selected</VCardText>
              </div>
              <VDivider />
              <VCardTitle>複方</VCardTitle>
              <VDivider />
              <div v-if="onlySingular.length">
                <VCard
                  v-for="med in onlySingular"
                  :key="med.item.name"
                  elevation="5"
                  color="secondary"
                  class="ma-4 pa-2"
                  dense
                >
                  <VContainer class="mx-2 pa-0">
                    <VCol align="center" justify="center" class="ma-0 pa-0">
                      <VRow align="center" justify="center" class="my-0 mx-3 pa-0">
                        <h3>{{ med.item.name }}</h3>
                        <VSpacer />
                        <VCol cols="1" align="right" class="my-0 mx-2 pa-0">
                          <VTextField v-model="med.item.amount" align="right" disabled></VTextField>
                        </VCol>
                        <p class="my-0 mx-2 pa-0" align="left">錢</p>
                      </VRow>
                    </VCol>
                  </VContainer>
                </VCard>
              </div>
              <div v-else>
                <VCardText>There are no 複方 selected</VCardText>
              </div>
            </div>
            <div v-else>
              <VCardTitle>Conversion per Medicine</VCardTitle>
              <VDataTable
                :headers="headers"
                :items="allMed"
                group-by="category"
                class="elevation-1"
              ></VDataTable>
              <VCardTitle>Total Conversion</VCardTitle>
              <VDataTable :headers="headers" :items="convertTotal" class="elevation-1"></VDataTable>
            </div>
          </VCard>
        </VCol>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationConvert',
  data: () => ({
    dialogOpened: false,
    viewMode: 'Medicine',
    onlyCompound: [],
    onlySingular: [],
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: '克', value: '克', sortable: false },
      { text: '分', value: '分', sortable: false },
      { text: '兩', value: '兩', sortable: false },
      { text: '錢', value: '錢', sortable: false },
    ],
    allMed: [],
  }),
  computed: {
    convertTotal() {
      const data = [];
      const medication = this.getDiagnosisData();
      medication.forEach((med) => {
        if (med.type === '單方') {
          const headers = this.convertPerMed(med);
          headers.forEach((ingredient) => {
            const index = data.findIndex((i) => i.name === ingredient.name);
            if (index !== -1) {
              data[index].錢 += ingredient.錢;
              data[index].克 += ingredient.克;
              data[index].分 += ingredient.分;
              data[index].兩 += ingredient.兩;
            } else {
              data.push(ingredient);
            }
          });
        } else {
          const conv = this.convertPerMed(med)[0];
          const index = data.findIndex((i) => i.name === med.name);
          if (index !== -1) {
            data[index].錢 += conv.錢;
            data[index].克 += conv.克;
            data[index].分 += conv.分;
            data[index].兩 += conv.兩;
          } else {
            data.push(conv);
          }
        }
      });
      return data;
    },
  },
  created() {
    componentBus.$on('medication-convert', () => {
      this.onlyCompound = [];
      this.onlySingular = [];
      const diagnosisData = this.getDiagnosisData();
      diagnosisData.forEach((item) => {
        if (item.type === '單方') {
          // console.log('單方 found!');
          if (Object.keys(item.ingredient).length === 0) {
            // console.log('it empty..');
            item.yield = 0;
            item.density = 0;
            // make dummy ingredients
            item.ingredient = {
              'ㄆ 複方 0': 0,
              'ㄕ 複方 1': 0,
              'ㄇ 複方 2': 0,
            };
            // console.log(this.getDiagnosisData);
          }
          this.onlyCompound.push({ item, show: false });
          // console.log(item);
        } else {
          // console.log('複方 found!');
          this.onlySingular.push({ item, show: false });
        }
      });
      this.dialogOpened = true;
    });
  },
  methods: {
    show(i) {
      console.log(i);
    },
    convertAllMed() {
      this.allMed = [];
      const medication = this.getDiagnosisData();
      // console.log('-----------------------');
      medication.forEach((med) => {
        this.allMed = this.allMed.concat(this.convertPerMed(med));
      });
    },
    convertPerMed(medicine) {
      const data = [];
      if (medicine.type === '單方') {
        if (medicine.yield === 0 || medicine.density === 0) {
          return data;
        }
        Object.keys(medicine.ingredient).forEach((key) => {
          const 錢 =
            ((medicine.amount / 3.75) * (medicine.ingredient[key] / medicine.density)) / 3.75;
          const newHeader = {
            category: medicine.name,
            name: key,
            錢: Math.round(錢 * 100) / 100,
            克: Math.round(錢 * 3.75 * 100) / 100,
            分: Math.round(錢 * 10 * 100) / 100,
            兩: Math.round((錢 / 10) * 100) / 100,
          };
          data.push(newHeader);
        });
      } else {
        const newHeader = {
          name: medicine.name,
          category: '複方',
          錢: Math.round(medicine.amount * 100) / 100,
          克: Math.round(medicine.amount * 3.75 * 100) / 100,
          分: Math.round(medicine.amount * 10 * 100) / 100,
          兩: Math.round((medicine.amount / 10) * 100) / 100,
        };
        data.push(newHeader);
      }
      return data;
    },
    changeAmount(toChange, category) {
      const { currentPatientDiagnosis } = this.$store.state;
      const index = this.$store.state.patientMedicationInfoArray.findIndex(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      if (category === 'yield' || category === 'density') {
        this.$store.state.patientMedicationInfoArray[index][category] = Number(toChange);
      } else {
        this.$store.state.patientMedicationInfoArray[category] = Number(toChange);
      }
    },
    getDiagnosisData() {
      const { currentPatientDiagnosis } = this.$store.state;
      const medicationTable = this.$store.state.patientMedicationInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return medicationTable.medication;
    },
    medShow(med) {
      if (med.item.type === '單方') {
        this.onlyCompound.forEach((item) => {
          if (item === med) {
            item.show = !item.show;
          } else {
            item.show = false;
          }
        });
      } else {
        this.onlySingular.forEach((item) => {
          if (item === med) {
            item.show = !item.show;
          } else {
            item.show = false;
          }
        });
      }
    },
  },
});
</script>

<style scoped>
/deep/ .v-text-field {
  width: 30px;
  text-align: center;
}
</style>
