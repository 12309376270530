import Vue from 'vue';
import VueRouter from 'vue-router';

import Diagnosis from '@/views/Diagnosis';
import Home from '@/views/Home';
import Profile from '@/views/Profile';
import SuggestedPrescription from '@/components/Diagnosis/SuggestedPrescription';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/sp',
    name: 'SuggestedPrescription',
    component: SuggestedPrescription,
  },
  {
    path: '/diagnosis/:medicalOrderNumber',
    name: 'Diagnosis',
    component: Diagnosis,
  },
  {
    path: '*',
    redirect: '/',
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
