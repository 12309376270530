<template>
  <div class="profile">
    <VCard width="80%" class="mx-auto">
      <VList>
        <VContainer fluid>
          <VRow>
            <VCol align="center">
              <VAvatar size="200">
                <VImg
                  v-if="$store.state.profile.photoUrl"
                  :aspect-ratio="16 / 9"
                  contain
                  darkd
                  lazy-src
                  :src="$store.state.profile.photoUrl"
                  alt="頭像"
                />
                <VImg
                  v-else
                  :aspect-ratio="16 / 9"
                  contain
                  darkd
                  lazy-src
                  src="@/assets/images/grey_silhouette"
                  alt="頭像"
                />
              </VAvatar>
            </VCol>
          </VRow>
          <VRow>
            <VCol align="center">
              <VListItem>
                <VListItemContent class="px-0 mr-n12">
                  <VCardTitle class="justify-center text-h5">
                    {{ $store.state.profile.name }}
                  </VCardTitle>
                  <VCardSubtitle>{{ $store.state.profile.email }}</VCardSubtitle>
                </VListItemContent>
                <VListItemAction class="px-0 mx-0">
                  <VBtn icon @click="msgPop('此功能尚未開放')">
                    <VIcon>mdi-square-edit-outline</VIcon>
                  </VBtn>
                </VListItemAction>
              </VListItem>
            </VCol>
          </VRow>
        </VContainer>
      </VList>
      <VDivider />
      <VCardText class="justify-center">{{ $store.state.profile.name }} 醫生你好</VCardText>
    </VCard>

    <VFooter dark padless fixed>
      <VContainer class="pa-0 d-flex justify-center" fluid dark>
        <div class="text-caption">
          Copyright ©
          <a class="d-inline-block"><!-- TODO --></a>
          {{ new Date().getFullYear() }}
        </div>
      </VContainer>
    </VFooter>
    <MessagSnackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import MessageSnackbar, { MessageSnackbarType } from '@/mixins/MessageSnackbarMixin';

export default Vue.extend({
  name: 'Profile',
  mixins: [MessageSnackbar],
  methods: {
    msgPop(str) {
      this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, str, 'close');
    },
  },
});
</script>
