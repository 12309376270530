<template>
  <div class="app-add-patient-dialog">
    <VDialog persistent :value="value" max-width="1080px">
      <VCard>
        <VToolbar dense dark class="primary">
          <VToolbarTitle>新增病患資料</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="close()">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VForm ref="form" v-model="isValid" :disabled="requesting">
          <VContainer>
            <VRow>
              <VCol>
                <VTextField
                  id="name"
                  v-model="patientInfo.name"
                  clearable
                  label="姓名"
                  name="name"
                  prepend-icon="mdi-account-circle"
                  :rules="[inputRules.required]"
                ></VTextField>
              </VCol>
              <VCol>
                <VSelect
                  v-model="patientInfo.gender"
                  label="性別"
                  prepend-icon="mdi-gender-male-female"
                  :items="genderList"
                  :rules="[() => !!patientInfo.gender || '必須的']"
                ></VSelect>
              </VCol>
              <VCol>
                <VTextField
                  id="medicalOrderNumber"
                  v-model="patientInfo.medicalOrderNumber"
                  clearable
                  label="病歷號"
                  name="medicalOrderNumber"
                  prepend-icon="mdi-clipboard-account"
                  :rules="[() => !!patientInfo.medicalOrderNumber || '必須的']"
                ></VTextField>
              </VCol>
              <VCol>
                <VTextField
                  id="id"
                  v-model="patientInfo.cardId"
                  clearable
                  label="健保卡卡號"
                  name="id"
                  prepend-icon="mdi-card-account-details"
                  :rules="[() => !!patientInfo.cardId || '必須的']"
                ></VTextField>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VSelect
                  v-model="patientInfo.period"
                  label="時段"
                  prepend-icon="mdi-clipboard-clock-outline"
                  :items="
                    Array(5)
                      .fill()
                      .map((val, idx) => idx + 1)
                  "
                  :rules="[() => !!patientInfo.period || '必須的']"
                ></VSelect>
              </VCol>
              <VCol>
                <VSelect
                  v-model="patientInfo.consultationNo"
                  label="診號"
                  prepend-icon="mdi-content-paste"
                  :items="
                    Array(5)
                      .fill()
                      .map((val, idx) => idx + 1)
                  "
                  :rules="[() => !!patientInfo.consultationNo || '必須的']"
                ></VSelect>
              </VCol>
              <VCol>
                <VSelect
                  v-model="patientInfo.status"
                  label="健保卡身分"
                  prepend-icon="mdi-smart-card"
                  :items="statusList"
                  :rules="[() => !!patientInfo.status || '必須的']"
                ></VSelect>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VSelect
                  v-model="patientInfo.department"
                  label="科別"
                  prepend-icon="mdi-city"
                  :items="departments"
                  :rules="[() => !!patientInfo.department || '必須的']"
                ></VSelect>
              </VCol>
              <VCol>
                <VTextField
                  id="arrears"
                  v-model="patientInfo.arrears"
                  clearable
                  label="欠費"
                  name="arrears"
                  prepend-icon="mdi-currency-usd"
                  :rules="[() => !!patientInfo.arrears || '必須的']"
                ></VTextField>
              </VCol>
              <VCol>
                <VTextField
                  id="remark"
                  v-model="patientInfo.remark"
                  clearable
                  label="備註"
                  name="remark"
                  prepend-icon="mdi-note"
                ></VTextField>
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
        <VCardActions>
          <VSpacer />
          <VBtn type="submit" class="blue darken-1" text :disabled="!isValid" @click="submit()">
            提交
          </VBtn>
        </VCardActions>
      </VCard>
      <VOverlay v-model="requesting">
        <VProgressCircular indeterminate size="64" />
      </VOverlay>
    </VDialog>
  </div>
</template>

<script>
import Vue from 'vue';

import MessageSnackbar, { MessageSnackbarType } from '@/mixins/MessageSnackbarMixin';
import extensions from '@/mixins/ExtensionsMixin';
import inputRules from '@/mixins/InputRulesMixin';

// import getAPI from '@/axios-api';

export default Vue.extend({
  name: 'AppAddPatientDialog',
  mixins: [extensions, MessageSnackbar, inputRules],
  props: {
    value: { type: Boolean, default: true },
  },
  data: () => ({
    patientInfo: {
      period: null,
      consultationNo: null,
      name: '',
      medicalOrderNumber: '',
      status: '',
      department: '',
      gender: '',
      cardId: '',
      remark: '',
      registerTime: null,
      arrears: null,
      selected: false,
    },
    statusList: ['兒童', '一般', '身障', '敬老'],
    departments: ['內科', '外科'],
    genderList: ['男', '女'],
    // patientListItems: [],
    isValid: false,
    requesting: false,
  }),
  methods: {
    async submit() {
      await (async () => {
        this.requesting = true;
        this.patientInfo.path = `/diagnosis/${this.patientInfo.medicalOrderNumber}`;
        // return firestore.collection('patients').add(this.patientInfo);
        // this.$store.state.patientInfo.push(this.patientInfo);
        this.patientInfo.gender = this.patientInfo.gender === '男' ? 'M' : 'F';
        this.patientInfo.registerTime = new Date().toISOString();
        this.requesting = false;
      })()
        .then(() => {
          this.$store.dispatch('createNewPatient', this.patientInfo);
          this.$store.dispatch('fetchPatientInfo');
        })
        .then(() => {
          this.close();
        })
        .catch((error) =>
          this.$_mixin_MessageSnackbar_show(MessageSnackbarType.error, error.message),
        );
      // console.log(this.$store.state.patientInfo);
    },
    close() {
      this.patientInfo = {
        period: null,
        consultationNo: null,
        name: '',
        medicalOrderNumber: '',
        status: '',
        department: '',
        cardId: '',
        remark: '',
        registerTime: null,
        arrears: '',
      };
      this.$refs.form.resetValidation();
      this.$emit('input', false);
    },
  },
});
</script>
