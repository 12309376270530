<template class="medicine-add">
  <VDialog v-model="dialogOpened" max-width="888px">
    <VCard>
      <VCardTitle>
        <span class="headline">{{ formTitle }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <VRow>
            <VCol cols="12" sm="6" md="4">
              <VSelect
                v-model="editedItem.name"
                :items="Object.keys($store.state.medicationStocks)"
                label="品項"
                hide-selected
              ></VSelect>
              <span v-if="editedItem.name !== ''">
                剩餘： {{ $store.state.medicationStocks[editedItem.name] }} 錢
              </span>
            </VCol>
            <VCol cols="12" sm="6" md="4">
              <VTextField
                v-model="editedItem.amount"
                :disabled="editedItem.name === ''"
                label="份量"
              ></VTextField>
            </VCol>
            <VCol cols="12" sm="6" md="4">
              <VSelect
                v-model="editedItem.usage"
                :items="['外用', '內服']"
                :disabled="editedItem.amount == ''"
                label="使用種類"
                hide-selected
              ></VSelect>
            </VCol>
            <VCol cols="12" sm="6" md="4">
              <VTextField v-model="editedItem.remarks" label="備註"></VTextField>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn color="blue darken-1" text @click="close()">取消</VBtn>
        <VBtn
          color="blue darken-1"
          text
          :disabled="editedItem.amount <= 0 || editedItem.usage == ''"
          @click="save()"
        >
          儲存
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';
import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationAdd',
  data: () => ({
    dialogOpened: false,
    editedItem: {
      name: '',
      usage: '',
      type: '',
      remarks: '',
      amount: 0,
      ingredient: {},
    },
    defaultItem: {
      name: '',
      usage: '',
      type: '',
      remarks: '',
      amount: 0,
      ingredient: {},
    },
    editedIndex: -1,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '新增項目' : '編輯項目';
    },
  },
  created() {
    componentBus.$on('medication-add', (medicine) => {
      if (
        !Object.keys(this.$store.state.medicationStocks).find(
          (title) => `${medicine.title}` === title,
        )
      ) {
        this.$store.state.medicationStocks[`${medicine.title}`] = 100;
      }
      this.editedItem.name = medicine.title;
      this.editedItem.type = medicine.type;
      this.dialogOpened = true;
    });
    componentBus.$on('medication-edit', (item) => {
      this.editedIndex = this.getDiagnosisData().medication.indexOf(item);
      this.editedItem = { ...item };
      this.dialogOpened = true;
    });
  },
  methods: {
    close() {
      this.dialogOpened = false;
      this.$nextTick().then(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      const diagnosisData = this.getDiagnosisData().medication;
      console.log(diagnosisData);
      if (this.editedIndex > -1) {
        const targetItem = diagnosisData[this.editedIndex];
        const amountDiff = Number(targetItem.amount) - Number(this.editedItem.amount);
        this.$store.state.medicationStocks[targetItem.name] =
          Number(this.$store.state.medicationStocks[targetItem.name]) + amountDiff;
        Object.assign(diagnosisData[this.editedIndex], this.editedItem);
      } else {
        diagnosisData.push(this.editedItem);
        const editItemName = this.editedItem.name;
        this.$store.state.medicationStocks[editItemName] -= this.editedItem.amount;
      }
      this.close();
    },
    getDiagnosisData() {
      const { currentPatientDiagnosis } = this.$store.state;
      const medicationTable = this.$store.state.patientMedicationInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return medicationTable;
    },
  },
});
</script>
