<template>
  <div class="image-table-dialog">
    <VDialog v-model="dialogOpened" persistent max-width="1000px">
      <VCard class="ma-0">
        <VToolbar dense dark class="primary">
          <VToolbarTitle>{{ $store.state.defaultImageTable.cardTitle }}</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="close()">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCol>
          <VRow class="ma-0">
            <VSpacer />
            <VBtn color="success" class="ma-1" @click="uploadDialogOpened = true">新增</VBtn>
            <VBtn color="primary" class="ma-1" @click="submitDialogOpened = true">完成</VBtn>
          </VRow>
          <VRow>
            <VCol>
              <VSimpleTable dense fixed-header height="500px">
                <template #default>
                  <thead>
                    <tr>
                      <th>名稱</th>
                      <th>註解</th>
                      <th>日期</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in imageTable" :key="item.date + i">
                      <td class="pa-1" @click="viewModeClicked(item)">
                        <VImg
                          max-height="100"
                          max-width="100"
                          contain
                          lazy-src
                          :src="imageOutput(item.image)"
                        />
                      </td>
                      <td>{{ item.remark }}</td>
                      <td>{{ item.date.toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </template>
              </VSimpleTable>
            </VCol>
          </VRow>
        </VCol>
      </VCard>
    </VDialog>
    <VDialog v-model="uploadDialogOpened" persistent max-width="600px">
      <VCard align="center">
        <VToolbar fixed dense class="primary ma-0 pa-0">
          <VToolbarTitle>新增</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="uploadDialogOpened = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <div class="ma-5">
          <template v-if="!imageToSave.file">
            <VSheet rounded="true" class="mx-auto noImage" height="400" width="400"></VSheet>
          </template>
          <template v-else>
            <VImg :src="imageToSave.src" width="400" height="400" contain />
          </template>
          <VFileInput
            v-model="imageToSave.file"
            accept="image/*"
            label="Select image"
            truncate-length="50"
            @change="showFile()"
          ></VFileInput>
          <VTextField v-model="imageToSave.remark" label="Remarks"></VTextField>
        </div>
        <VDivider />
        <VCardActions>
          <VSpacer />
          <VBtn
            color="success"
            :disabled="!imageToSave.src || imageToSave.remark === ''"
            @click="saveImage()"
          >
            Upload
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VDialog v-model="viewDialogOpened" persistent :max-width="maxViewWidth">
      <VCard max-height="650px" :class="viewMode">
        <VToolbar fixed dense class="primary ma-0 pa-0">
          <VToolbarTitle>View</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="viewDialogOpened = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCol class="ma-0">
          <VRow>
            <VRadioGroup v-model="viewMode" row class="ml-4">
              <VRadio label="Single View" value="single" @click="maxViewWidth = '600px'"></VRadio>
              <VRadio
                label="Multiple View"
                value="multiple"
                @click="maxViewWidth = '850px'"
              ></VRadio>
            </VRadioGroup>
          </VRow>
          <VRow>
            <template v-if="viewMode === 'single'">
              <VCol align="center" class="ma-0">
                <VImg
                  max-width="450"
                  max-height="400px"
                  contain
                  :src="imageOutput(selectedImage.image)"
                />
                <VDivider class="mt-3"></VDivider>
                <VCardTitle>{{ selectedImage.date.toLocaleString() }}</VCardTitle>
                <VCardText align="left">{{ selectedImage.remark }}</VCardText>
              </VCol>
            </template>
            <template v-else>
              <VCol>
                <VRow
                  v-for="i in Math.ceil(imageTable.length / 3)"
                  :key="i"
                  class="my-2"
                  justify="center"
                >
                  <VCard
                    v-for="ii in 3"
                    :key="ii + 'image'"
                    align="center"
                    width="250px"
                    class="mx-2"
                  >
                    <template v-if="selectedImages[imageIndex(i, ii)]">
                      <VImg
                        max-width="250px"
                        contain
                        :src="imageOutput(selectedImages[imageIndex(i, ii)].image)"
                        :class="selectedImages[imageIndex(i, ii)].selected ? '' : 'notselected'"
                      />
                      <VRow class="ml-3" align="center">
                        <VCheckbox
                          v-model="selectedImages[imageIndex(i, ii)].selected"
                          @click="isSelected(selectedImages[imageIndex(i, ii)].selected)"
                        ></VCheckbox>
                        <h4>
                          {{ selectedImages[imageIndex(i, ii)].date.toLocaleString() }}
                        </h4>
                      </VRow>
                      <p align="left" class="ml-4">
                        {{ selectedImages[imageIndex(i, ii)].remark }}
                      </p>
                    </template>
                  </VCard>
                </VRow>
              </VCol>
            </template>
          </VRow>
        </VCol>
      </VCard>
    </VDialog>
    <VDialog v-model="submitDialogOpened" persistent max-width="1100px">
      <VCard>
        <VToolbar fixed dense class="primary ma-0 pa-0">
          <VSpacer />
          <VBtn class="pr-3" text @click="submit()">Submit</VBtn>
          <VBtn icon @click="submitDialogOpened = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCol class="ma-0 pa-0">
          <template v-for="(item, i) in consultationTable">
            <VRow v-if="item.title" :key="item.title + i" class="ma-0 pa-0">
              <VCardTitle>
                {{ item.title }}
              </VCardTitle>
            </VRow>
            <VDivider :key="item.title + i + 'title'" />
            <VRow v-for="category in item.categories" :key="category.label" class="ma-0 pa-0">
              <VRadioGroup v-model="category.selected" class="ma-0 pa-0 grow">
                <VRow class="ma-0 pa-0" no-gutters>
                  <VCol class="ma-2 pa-2 shrink">
                    <VContainer justify-center align="center">
                      <h3>
                        {{ category.label }}
                      </h3>
                    </VContainer>
                  </VCol>
                  <VCol class="ma-0 pa-0">
                    <VRadioGroup v-model="category.selected">
                      <VRow
                        v-for="(option, op_index) in category.options"
                        :key="op_index + 'option'"
                        class="ma-0 pa-0"
                        dense
                      >
                        <VCol class="ma-0 pa-0">
                          <VRadio :label="op_index" :value="op_index + ' - ' + option" />
                        </VCol>
                        <VCol class="ma-0 pa-0 grow">
                          <p>{{ option }}</p>
                        </VCol>
                      </VRow>
                    </VRadioGroup>
                  </VCol>
                </VRow>
                <VDivider />
              </VRadioGroup>
            </VRow>
          </template>
        </VCol>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import Vue from 'vue';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import componentBus from '@/main';
// import axios from 'axios'; For uploading image

import ExtensionsMixin from '@/mixins/ExtensionsMixin';

export default Vue.extend({
  name: 'ImageTableDialog',
  mixins: [ExtensionsMixin],
  data: () => ({
    dialogOpened: false,
    viewDialogOpened: false,
    uploadDialogOpened: false,
    submitDialogOpened: false,
    viewMode: 'single',
    maxViewWidth: '500px',
    selectedImage: {
      image: null,
      date: '',
      remark: '',
    },
    selectedImages: [],
    imageToSave: {
      file: null,
      src: null,
      remark: '',
    },
    tongueConsultation: [
      {
        title: '舌質',
        categories: [
          {
            label: '舌色',
            selected: null,
            options: {
              淡舌: '舌色較正常淺淡，主虛証、寒証，多見於血虛，為陽氣衰弱、氣血不足象。色淡而胖嫩為虛寒；胖嫩而邊有齒痕為氣虛、陽虛。',
              紅舌: '舌色較正常深，呈鮮紅色，主熱証，多為裡熱實証。舌尖紅是心火上炎；舌邊紅為肝膽有熱；紅而乾為熱傷津液或陰虛火旺。',
              絳舌: '舌色深紅，為熱盛，多為邪熱深入營分、血分或陰虛火旺。',
              瘀斑舌: '舌上有青紫色之瘀點或斑點，多為內有瘀血蓄積。',
              青紫舌:
                '全舌舌質呈現青紫，或為熱极，或為寒証。舌質絳紫色深而乾燥為熱极，溫熱病者為病邪傳入營分、血分；舌質淡黃紫或青紫而滑潤者為陰寒証。',
            },
          },
          {
            label: '舌形(舌質)',
            selected: null,
            options: {
              老嫩: '“老”即指舌質紋理粗糙，形色堅斂，多屬實証、熱証：“嫩”指舌質紋理細膩，形色浮嫩，多屬虛証或虛寒証。',
              胖瘦: '“胖”指舌體胖大、腫脹，多與水濕停留有關。舌質淡而胖，舌邊有齒痕者，多屬脾虛或腎陽虛、水濕停留；舌質紅而腫脹，多屬濕熱內蘊或熱毒亢盛。“瘦”指舌體瘦小而薄，多屬虛証。舌質淡而舌形瘦者，多為氣血不足；舌質紅絳而舌形瘦者，多屬陰虛內熱。',
              芒刺: '舌乳頭增生、肥大，突起如刺，多屬熱邪亢盛。熱邪越重，芒刺越大、越多。臨床上芒刺多見於舌尖與舌邊，舌尖芒刺多屬肝膽熱盛。',
              裂紋: '舌體上有多种縱行或橫行的裂溝或皺紋，多由於粘膜萎縮而形成。裂紋舌可見於少數正常人。舌質紅絳而有裂紋者多屬熱盛；舌質淡而有裂紋者多屬氣陰不足。',
            },
          },
          {
            label: '舌態(舌體)',
            selected: null,
            options: {
              震顫: '舌體不自主地顫抖，多屬氣血兩虛或肝風內動。',
              歪斜: '舌體偏歪於一側，多為中風偏癱或中風先兆。',
              痿軟: '舌體伸卷無力，多因氣血俱虛筋脈失養所致。',
              強硬: '舌體不柔和，屈伸不利，甚或不能轉動，多屬高熱傷津，邪熱熾盛，或為中風的征兆。',
            },
          },
        ],
      },
      {
        title: '舌苔',
        categories: [
          {
            label: '苔色',
            selected: null,
            options: {
              白苔: '白苔是臨床上最常見的，其它顏色的苔可以認為是白苔基礎上轉化而形成的。白苔一般屬肺，主表証、寒証，但臨床上也有裡証、熱証而見白苔者。如薄白而潤為風寒；薄白而燥為風熱；寒濕之裡証可見白而厚膩之苔。',
              黃苔: '有淡黃、嫩黃、深黃、焦黃等不同。一般說，黃苔的顏色越深，則熱邪越重。淡黃為微熱；嫩黃熱較重；深黃熱更重；焦黃則為熱結；黃而乾為熱傷津；黃而膩則為濕熱。',
              灰黑苔:
                '多主熱証，亦有寒濕或虛寒証。舌苔灰黑而乾，為熱盛傷津；舌苔灰黑而濕潤，多屬陽虛寒盛。灰黑苔多見於疾病比較嚴重的階段。',
            },
          },
          {
            label: '厚薄',
            selected: null,
            options: {
              薄苔: '薄苔多為疾病初起，病邪在表，病情較輕；',
              厚苔: '多示病邪較盛，并已傳裡；或有胃腸積滯；或有痰濕',
              少苔: '常表示機體正氣不足',
              無苔: '胃氣大虛，缺乏生發之機。',
              花剝苔:
                '舌面上有不規則的舌苔剝脫，剝脫處光滑無苔，多屬胃的氣陰不足，若兼有膩苔則表示痰濕未化而正氣已傷。',
            },
          },
          {
            label: '潤燥',
            selected: null,
            options: {
              膩苔: '苔質致密、細膩如一層混濁光滑的粘液覆蓋於舌面，不易擦去，多屬痰濕內盛。',
              腐苔: '苔質疏松如豆腐渣，堆於舌面，易於擦去，多為實熱蒸化胃中食濁，為胃中宿食化腐的表現。',
            },
          },
        ],
      },
    ],
    eyeConsultation: [
      {
        title: '眼診',
        categories: [
          {
            label: '肝區',
            selected: null,
            options: {
              '肝區平': '-',
              '肝區有圈紋': '眼診肝區有圈紋代表脂肪肝，也可稱肝積肥氣。 (  難經：  肝積肥氣  )',
              '肝區損傷': '肝木脾土失去平衡;胰區過大肝區損??',
              '肝區有裂口裂痕': '肝炎',
              '肝區多洞': '表肝積毒素',
              '肝區有白點(癌症型白點)':
                '(癌症型白點)肝硬化、肝癌，內必有東西  (  亦可能有五金毒  )  ，須清出，攻堅。',
              '肝區有破洞': '就像在土裏的爛木，肝區有破洞但表肝積毒素。（服西藥過多）',
              '肝區有褐點': '褐點乃毒素 （疫苖及抗生素造成）',
              '肝區有白點(毒素累積型白點)': '毒素累積型白點，累積毒素。（服西藥過多）',
              '肝區洞多不平整': '肝區與心區間顯示心包有痰 ',
              '肝區有藍白相間的白點':
                '白點晶瑩剔透，色澤好，為  “  生  ”  之氣。如是乾燥的白，則不好。',
              '肝區木紋鬆紋路不好': '木紋鬆表受損',
              '肝區平整無紋': '需去清肝解毒',
              '肝區大': '木(肝)氣不舒',
              '肝區太小無紋路': '土反剋木 (胰臟反侮肝臟)',
              '肝區破洞乾燥': '表肝積毒素發炎，肝區受損重',
              '肝區有很多橫紋': '太乾燥(肝木要滋潤)，肝區有橫紋→脂肪肝',
              '肝區有白點及橫紋': '肝區白點多表肝中有積毒及積肥氣',
              '肝區青黃很多洞': '-',
              '肝區有黃點，紋路好': '黃點代表濕熱',
              '肝區有白紋，混濁': '-',
              '肝區有白點，白色帶狀沉澱物': '脂肪肝，毒素堆積',
              '肝區有斑': '-',
              '肝區黃花斑': '土反侮木，會蓄水，故要甘淡滲利',
              '肝區紋多': '有白內障',
              '肝區較小，有圈紋': '土不制水，水反侮土，土反侮木',
            },
          },
          {
            label: '心區',
            selected: null,
            options: {
              '心區扁平': '心陽不足，心臟力量不夠',
              '心區色淡': '心血不足',
              '心區太扁平且色白': '心臟功能仍不足',
              '心區扁平色淡黃白': '心臟無力且血極虛',
              '心區太白也沒有鼓起來(形扁)': '心陰心陽皆不足',
              '心區內眥小': '心陽不足',
              '心區扁平色淡黃白 ': '-',
              '心區扁，沒有突出的一粒': '正常應該突出一粒',
              '心區變大贅肉多': '心臟有肥大',
              '下眼白血絲多': '血虛',
              '心區白色斑點': '心陽不足',
              '心區色白，扁散': '心血不足',
              '心區太白也沒有鼓起來(形扁) ': '心陰陽皆不足',
              '心區心區扁平蒼白': '心血不足，心陽不足',
              '心區痰飲': '心陽不足；眼心包有痰',
              '心區扁平色淡黃白  ': '心臟無力且血極虛',
              '心區血脈擴張': '腎水不能制火',
            },
          },
          {
            label: '脾區',
            selected: null,
            options: {
              '脾區過大': '脾陽不足沒有胃口，脾土虛，木不剋土，水反侮土，故土虛，脾區會變大',
              '脾區稍小': '脾陰不足',
              '肝脾區質－黃濁': '-',
              '胰區與脾區分開': '照理說應合在一起 > 此人胰臟退化。',
              '脾區有白點': '白點 代表陰實，(診斷：西醫胰臟癌 厥陰證寒熱夾雜 并大承氣湯證)',
              '脾區乾黃': '脾陽不足',
              '脾區過大 ': '濕太盛，或缺乏運動所致',
            },
          },
          {
            label: '肺區',
            selected: null,
            options: {
              '肺區血絲多': '心肺間痰濕重',
              '肺區(眼白區)有白點、白白一塊': '肺中有痰',
              '肺區肺白入侵瞳孔，眼上方有黃褐班，虹膜下方有白點': '肺中濕熱，痰積造成',
              '肺區：有黃痰': '-',
              '肺區有白點': '因有痰',
              '肺區有三根雞爪': '癲癇',
              '肺區上部有明顯的雞爪紋': '如果這碰到眼珠子，癲癇就會發作',
              '眼肺區有白痰塊': '肺中有痰飲',
              '肺區靠內,粉紅': '濕熱',
              '肺區有褐點': '褐點=肺癌BREAST CANCER',
              '肺區有濁點': '褐點=肺癌BREAST CANCER',
              '肺區痰多，血脈賁張': '(  因肺中壓力大  )',
              '肺區淡褐色斑塊': '淡褐色斑塊=肺中寒濕',
              '肺區有黃白點': '大量痰',
              '肺區有黑點': '黑點是腫瘤(肺癌)',
              '肺區有白點 腫瘤(肺癌)': '診斷：裡寒濕，陰實',
              '肺區上方有藍色小點': '藍色小點 (寄生蟲)',
              '左肺區白點為肺癌': '肺癌  (肺癌型白點)',
              '右肺區青色為  pul.TB  肺結核': '青色=肺結核',
              '肺區痰多，結膜處許多小水泡': '痰多(結膜處許多小水泡)',
            },
          },
          {
            label: '腎區',
            selected: null,
            options: {
              '腎區反應較差': '腎陽不足',
              '腎區大': '陽虛時陰會外走，故瞳孔變大',
              '腎區大、反應差': '腎陽不足',
              '腎區反射差': '腎陽不足',
              '腎區形已變': '-',
              '腎區反應差(右好於左)': '左腎較萎縮',
              '腎區瞳孔過大': '腎陽虛，腎陽不足',
              '腎區反應差': '腎陽不足',
              '腎區無反應': '腎陽極虛',
              '腎區過大': '腎陽不足',
              '腎區:瞳孔大且收縮太慢': '腎陽不足',
            },
          },
        ],
      },
    ],
  }),
  computed: {
    consultationTable() {
      if (this.$store.state.defaultImageTable.cardTitle === '眼診') {
        return this.eyeConsultation;
      }
      return this.tongueConsultation;
    },
    imageTable() {
      let symptomTable = this.$store.state.patientImageArray.find(
        (item) => item.patientId === this.$store.state.currentPatientDiagnosis.id,
      );
      if (this.$store.state.defaultImageTable.cardTitle === '眼診') {
        symptomTable = symptomTable.yanImages;
      } else {
        symptomTable = symptomTable.sheImages;
      }
      symptomTable = symptomTable.sort((a, b) => b.date - a.date);
      return symptomTable;
    },
  },
  created() {
    componentBus.$on('adjust-content', (name) => {
      const symptomTable = this.getSymptomTable();
      if (name === '眼') {
        this.$store.state.defaultImageTable = {
          cardTitle: '眼診',
          defaultImage:
            'https://www.worldatlas.com/r/w1200/upload/d8/17/c1/shutterstock-394292725.jpg',
        };
        this.eyeConsultation.forEach((item) => {
          item.categories.forEach((categ) => {
            const selected = symptomTable.diagnosis.yan.data.find(
              (select) => select[0] === categ.label,
            );
            if (selected !== undefined) {
              const [label, content, remark] = selected;
              categ.selected = content;
              categ.label = remark;
              categ.label = label;
            } else {
              categ.selected = null;
            }
          });
        });
        this.dialogOpened = true;
        // symptomTable.diagnosis.yan
      } else if (name === '舌') {
        this.$store.state.defaultImageTable = {
          cardTitle: '舌診',
          defaultImage:
            'https://ietp-web-cdn-eandt-cache.azureedge.net/4/1/1/d/e/b/411deb1b48d2582ce8ce1cdd5300bbf73990941a.jpg',
        };
        this.tongueConsultation.forEach((item) => {
          item.categories.forEach((categ) => {
            const selected = symptomTable.diagnosis.she.data.find(
              (select) => select[0] === categ.label,
            );
            if (selected !== undefined) {
              const [label, content, remark] = selected;
              categ.selected = content;
              categ.label = remark;
              categ.label = label;
            } else {
              categ.selected = null;
            }
          });
        });
        this.dialogOpened = true;
      }
    });
  },
  methods: {
    submit() {
      const table = [];
      this.consultationTable.forEach((item) => {
        item.categories.forEach((category) => {
          if (category.selected !== null) {
            table.push([category.label, category.selected, '']);
          }
        });
      });
      this.$store.state.patientDiagnosisInfoArray.forEach((item) => {
        if (item.patientId === this.$store.state.currentPatientDiagnosis.id) {
          if (this.$store.state.defaultImageTable.cardTitle === '眼診') {
            item.diagnosis.yan.data = table;
          } else {
            item.diagnosis.she.data = table;
          }
        }
      });
      this.$store.state.patientDiagnosisInfoArray.push(' ');
      this.$store.state.patientDiagnosisInfoArray.pop();
      this.consultationTable.forEach((item) => {
        item.categories.forEach((category) => {
          category.selected = null;
        });
      });
      this.submitDialogOpened = false;
      this.close();
    },
    showFile() {
      const src = URL.createObjectURL(this.imageToSave.file);
      this.imageToSave.src = src;
    },
    saveImage() {
      const newRecord = {
        file: this.imageToSave.file,
        image: this.imageToSave.src,
        date: new Date(),
        remark: this.imageToSave.remark,
      };
      this.$store.state.patientImageArray.forEach((item) => {
        if (item.patientId === this.$store.state.currentPatientDiagnosis.id) {
          if (this.$store.state.defaultImageTable.cardTitle === '眼診') {
            item.yanImages.push(newRecord);
          } else {
            item.sheImages.push(newRecord);
          }
        }
      });
      this.uploadDialogOpened = false;
      this.imageToSave = {
        file: null,
        src: null,
        remark: '',
      };
    },
    viewModeClicked(selectedImage) {
      this.selectedImage = selectedImage;
      this.selectImages();
      this.viewDialogOpened = true;
    },
    imageIndex(i, ii) {
      return (i - 1) * 3 + ii - 1;
    },
    selectImages() {
      const images = this.imageTable;
      images.forEach((item) => {
        item.selected = item === this.selectedImage;
      });
      this.selectedImages = images;
    },
    isSelected(i) {
      this.selectedImages.push(i);
      this.selectedImages.pop();
    },
    imageOutput(image) {
      if (image === null) {
        return this.$store.state.defaultImageTable.defaultImage;
      }
      return image;
    },
    close() {
      this.dialogOpened = false;
    },
    getSymptomTable() {
      const { currentPatientDiagnosis } = this.$store.state;
      // console.log(currentPatientDiagnosis);
      const symptomTable = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatientDiagnosis.id,
      );
      return symptomTable;
    },
  },
});
</script>

<style scoped>
.multiple {
  overflow-y: scroll;
}
.notselected {
  filter: opacity(10%);
}
.noImage {
  border: 4px dashed gray;
}
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 43%;
}
</style>
