<template>
  <div id="diag" class="diagnosis">
    <VToolbar color="primary">
      <VRow>
        <VCol cols="3" sm="2">病歷號：{{ currentPatientInfo.medicalOrderNumber }}</VCol>
        <VCol cols="3" sm="2">姓名：{{ currentPatientInfo.name }}</VCol>
        <template v-if="!$vuetify.breakpoint.mobile">
          <VCol cols="2">
            性別：
            <VIcon v-if="currentPatientInfo.gender === 'M'">mdi-gender-male</VIcon>
            <VIcon v-else-if="currentPatientInfo.gender === 'F'">mdi-gender-female</VIcon>
            <VIcon v-else>mdi-help</VIcon>
          </VCol>
        </template>
        <VCol
          :class="!$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0'"
          cols="3"
          sm="2"
        >
          健保：{{ currentPatientInfo.status }}
        </VCol>
        <VSpacer />
        <VBtn rounded outlined class="my-auto" @click.prevent="submit()">
          <VIcon>mdi-check-bold</VIcon>
          完成
        </VBtn>
      </VRow>
    </VToolbar>
    <VContainer fluid>
      <VRow>
        <VCol cols="12" sm="6">
          <VRow>
            <VCol cols="12">
              <VCard class="pt-0">
                <VCardTitle class="text-h6 pb-0">過去病史</VCardTitle>
                <VCardText class="py-2 mt-0">
                  <VCombobox
                    v-model="patientDiagnosisInfo_fake.medicalHist"
                    class="pt-0 mt-0"
                    :items="diseaseOptions"
                    clearable
                    multiple
                    persistent-hint
                    hint="若不在選項中請手動輸入"
                    label="選擇或輸入過去病史"
                  >
                    <!--
                      <template #selection="{ attrs, item, select, selected }">
                      <VChip
                      v-bind="attrs"
                      :input-value="selected"
                      :ripple="false"
                      small
                      close
                      @click="select()"
                      @click:close="remove(patientDiagnosisInfo.medicalHist, item)"
                      >
                      <strong>{{ item }}</strong>
                      &nbsp;
                      <span v-if="!isCurrentItemInOptions(diseaseOptions, item)">（其他）</span>
                      </VChip>
                      </template> 
                    -->
                  </VCombobox>
                </VCardText>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VCard style="height: calc(100vh - 284px)" class="scroll">
                <VCardTitle>症狀</VCardTitle>
                <SymptomTable v-model="patientDiagnosisInfo_fake.symptom" />
              </VCard>
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="12" sm="6">
          <VRow>
            <VCol cols="12">
              <VCard>
                <VCardTitle class="text-h6 pb-0">病名與體格</VCardTitle>
                <VCardText class="py-0 mx-0">
                  <VContainer fluid>
                    <VRow>
                      <VCol class="pb-0">
                        <VTextField
                          v-model="patientDiagnosisInfo_fake.diseaseName"
                          class="py-0 mt-0"
                          label="病名"
                          clearable
                          :rules="[inputRules.required]"
                        ></VTextField>
                      </VCol>
                      <VCol class="pb-0">
                        <VCombobox
                          v-model="patientDiagnosisInfo_fake.physique"
                          class="py-0 mt-0"
                          :items="physiqueOptions"
                          clearable
                          small-chips
                          hide-selected
                          :rules="[inputRules.required]"
                          persistent-hint
                          hint="若不在選項中請手動輸入"
                          label="體格"
                        >
                          <template #selection="{ attrs, item, select, selected }">
                            <VChip
                              v-bind="attrs"
                              :input-value="selected"
                              :ripple="false"
                              small
                              close
                              @click="select()"
                              @click:close="patientDiagnosisInfo_fake.physique = ''"
                            >
                              <strong>{{ item }}</strong>
                              <span
                                v-if="
                                  !isCurrentItemInOptions(patientDiagnosisInfo_fake.physique, item)
                                "
                              >
                                （其他）
                              </span>
                            </VChip>
                          </template>
                        </VCombobox>
                      </VCol>
                    </VRow>
                  </VContainer>
                </VCardText>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VCard style="height: calc(100vh - 270px)">
                <MedicationTable />
              </VCard>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

import ExtensionsMixin from '@/mixins/ExtensionsMixin';
import InputRulesMixin from '@/mixins/InputRulesMixin';
import MedicationTable from '@/components/Diagnosis/MedicationTable';
import SymptomTable from '@/components/Diagnosis/SymptomTable';

// import  from '@/axios-api';

export default Vue.extend({
  name: 'Diagnosis',
  components: { SymptomTable, MedicationTable },
  mixins: [ExtensionsMixin, InputRulesMixin],
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('patientTempStore', {
      idx: this.patientStoreIdx,
      data: this.patientDiagnosisInfo,
    });
    next();
  },
  data: () => ({
    diseaseOptions: ['心臟病', '高血壓', '糖尿病'],
    physiqueOptions: ['瘦弱無力', '瘦但有力', '中等', '虛胖', '壯碩'],
    currentPatient: null,
    patientStoreIdx: null,
    // Dummy data
    patientDiagnosisInfo_fake: {
      medicalHist: [],
      diseaseName: 'A',
      physique: '瘦但有力',
      symptom: { 問: {}, 望: {}, 脈: {}, 觸: {}, 切: {} },
      medication: [1],
      id: 5,
    },
  }),
  computed: {
    currentPatientInfo() {
      const currentPatient = this.getCurrentPatient();
      const currentPatientDiagnosis = this.$store.state.patientDiagnosisInfoArray.find(
        (item) => item.patientId === currentPatient.id,
      );
      // if diagnosisInfo does not exist
      if (!currentPatientDiagnosis) {
        this.$store.commit('createDiagnoseInfo', currentPatient.id);
        this.$store.commit('createMedicationInfo', currentPatient.id);
        console.log('[CREATED] DiagnosisInfo for a patient.');
      } else {
        console.log(currentPatientDiagnosis);
      }
      this.$store.commit('setCurrentDiagnosePatient', currentPatient);
      // console.log(this.$store.state.patientDiagnosisInfoArray);
      return currentPatient;
    },
    diagnosisInfo() {
      return this.$store.state.symptomTableSimpleTable[this.$store.state.patient][1];
    },
    getNow() {
      const days = ['日', 'ㄧ', '二', '三', '四', '五', '六'];
      const now = new Date();
      const date = `${now.getFullYear()}/${now.getMonth()}${1}/${this.padNumber(now.getDate(), 2)}`;
      const time = `${this.padNumber(now.getHours(), 2)}:${this.padNumber(
        now.getMinutes(),
        2,
      )}:${this.padNumber(now.getSeconds(), 2)}`;
      const day = days[now.getDay()];
      return `${date} （${day}） ${time}`;
    },
    patientDiagnosisInfo() {
      if (this.patientStoreIdx < 0) {
        return {
          medicalHist: [],
          physique: '',
          diseaseName: '',
          symptom: {},
          medication: [],
          id: this.$store.state.patientId,
        };
      }
      return this.$store.state.patientDiagnosisArray[this.patientStoreIdx];
    },
  },
  async created() {
    this.patientStoreIdx = this.$store.state.patientDiagnosisArray
      .map((item) => item.id)
      .indexOf(this.$store.state.patientId);
  },
  methods: {
    padNumber(num, size) {
      let padNum = num.toString();
      while (padNum.length < size) padNum = `0${padNum}`;
      return padNum;
    },
    remove(arr, item) {
      return arr.splice(arr.indexOf(item), 1);
    },
    isCurrentItemInOptions(arr, item) {
      return arr.indexOf(item) !== -1;
    },
    submit() {
      this.$store.commit('addPastPatient', this.currentPatientInfo);
      this.$store.dispatch('postDiagnosisInfo');
    },
    getCurrentPatient() {
      const { ownPatients } = this.$store.state.user;
      const { medicalOrderNumber } = this.$route.params;
      const currentPatient = ownPatients.find(
        (patient) => patient.medicalOrderNumber === medicalOrderNumber,
      );
      return currentPatient;
    },
  },
});
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>
