<template>
  <VDialog v-model="dialog" transition="dialog-transition" persistent max-width="1100px">
    <VCard>
      <VToolbar app color="primary">
        <VToolbarTitle>建議方藥</VToolbarTitle>
        <VSpacer />
        <VBtn class="pr-3" text elevation="1">專家討論系統平台</VBtn>
        <VBtn icon @click="close()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText>
        <VRow class="mt-3">
          <VCol class="col-5 pa-3 mr-3">
            <VRow align="center">
              <VIcon class="mx-2">mdi-magnify</VIcon>
              <VAutocomplete chips dense label="Search medicine" class="mr-3"></VAutocomplete>
            </VRow>
            <VRow>
              <VCol>最小: min amount</VCol>
              <VCol>最大: max amount</VCol>
            </VRow>
            <VDivider />
            <VCard height="500" fluid rounded="lg" outlined class="mt-8">
              <VCardTitle primary-title>Medicine Name</VCardTitle>
              <VCardText>medicine desc</VCardText>
            </VCard>
          </VCol>
          <VDivider vertical></VDivider>
          <VCol justify="center" align="center">
            <VCard max-height="615" style="overflow: auto" elevation="0">
              <VDataTable
                :headers="headers"
                :items="data"
                :items-per-page="-1"
                hide-default-footer
              ></VDataTable>
            </VCard>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SuggestedPrescription',
  data: () => ({
    dialog: true,
    headers: [
      { text: 'Case ID', value: 'id' },
      { text: 'Disease Name', value: 'disease' },
      { text: 'Prescribed Medicine', value: 'medicine' },
      { text: 'Dossage (g)', value: 'dossage' },
    ],
  }),
  computed: {
    data() {
      const table = [];
      [...Array(15).keys()].forEach((index) => {
        const map = {
          id: `000000${index}`,
          disease: `disease-${(index % 6) + 1}`,
          medicine: `medicine-${(index % 3) + 1}`,
          dossage: `${(index % 5) * 20 + 50}`,
        };
        table.push(map);
      });
      return table;
    },
  },
});
</script>
