<template>
  <VDialog
    class="app-sign-in-register-dialog"
    :value="value"
    persistent
    max-width="480px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VToolbar color="primary" dark dense>
        <VBtn
          icon
          :disabled="window === 'signIn'"
          @click="
            window = 'signIn';
            signInData.password = '';
          "
        >
          <VIcon>mdi-arrow-left</VIcon>
        </VBtn>
        <VIcon left>mdi-{{ window === 'signIn' ? 'login-variant' : 'account-plus' }}</VIcon>
        <VToolbarTitle>{{ window === 'signIn' ? '登入' : '註冊' }}</VToolbarTitle>
        <VSpacer />
        <VMenu offset-y transition="slide-y-transition">
          <template #activator="{ attrs, on }">
            <VBtn text v-bind="attrs" v-on="on">
              <VIcon left>mdi-translate</VIcon>
              <span class="subtitle-1 text-capitalize">{{ currentLanguageName }}</span>
              <VIcon right>mdi-menu-down</VIcon>
            </VBtn>
          </template>
          <VList dense>
            <VListItem
              v-for="(language, i) in languages"
              :key="`languages-${i}`"
              @click="(currentLanguageName = language.name), language.apply()"
            >
              <VListItemTitle>{{ language.name }}</VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
        <VBtn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <VIcon>mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}</VIcon>
        </VBtn>
      </VToolbar>
      <VWindow v-model="window" touchless>
        <VWindowItem
          v-for="windowItem in ['signIn', 'register']"
          :key="`window-${windowItem}`"
          :value="windowItem"
        >
          <VContainer>
            <VForm
              :id="`${windowItem}Form`"
              v-model="areFormsValid[windowItem]"
              :disabled="requesting"
              @submit.prevent
            >
              <VContainer>
                <VRow>
                  <VCol>
                    <VTextField
                      id="email"
                      v-model="signInData.email"
                      clearable
                      dense
                      label="信箱"
                      name="email"
                      prepend-icon="mdi-email"
                      type="email"
                      :rules="[inputRules.required, inputRules.email]"
                      @input="localStorage.setItem('signIn.email', $event)"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    <VTextField
                      v-model="signInData.password"
                      clearable
                      dense
                      counter
                      label="密碼"
                      prepend-icon="mdi-lock"
                      :append-icon="passwordShowing ? 'mdi-eye-off' : 'mdi-eye'"
                      :rules="[inputRules.required, inputRules.password]"
                      :type="passwordShowing ? 'text' : 'password'"
                      @click:append="passwordShowing = !passwordShowing"
                    />
                  </VCol>
                </VRow>
              </VContainer>
              <template v-if="windowItem === 'register'">
                <VDivider />
                <VContainer>
                  <VRow>
                    <VCol>
                      <VTextField
                        id="name"
                        v-model="registerData.name"
                        clearable
                        dense
                        label="姓名"
                        name="name"
                        prepend-icon="mdi-account"
                        :rules="[inputRules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <VTextField
                        id="photoURL"
                        v-model="registerData.photoURL"
                        clearable
                        dense
                        type="url"
                        label="Photo URL (optional)"
                        name="photoURL"
                        prepend-icon="mdi-account-circle"
                        :rules="[inputRules.url]"
                      />
                      <!-- TODO - VSelect roles -->
                    </VCol>
                  </VRow>
                </VContainer>
              </template>
            </VForm>
          </VContainer>
        </VWindowItem>
      </VWindow>
      <VDivider />
      <VCardActions>
        <VBtn
          v-if="window === 'signIn'"
          color="warning"
          depressed
          :disabled="requesting"
          @click="
            signInData.password = '';
            window = 'register';
          "
        >
          註冊
        </VBtn>
        <VSpacer />
        <VCheckbox
          v-model="isRememberMeChecked"
          class="px-2 py-0 ma-0"
          hide-details
          dense
          :disabled="requesting"
          @click="localStorage.setItem('signIn.isRememberMeChecked', isRememberMeChecked)"
        >
          <template #label>
            <span style="line-height: normal" class="ml-n1 text-button">記住我</span>
          </template>
        </VCheckbox>
        <VBtn
          depressed
          type="submit"
          :form="`${window}Form`"
          color="primary"
          :loading="requesting"
          :disabled="requesting || !areFormsValid[window]"
          @click="window === 'signIn' ? signIn() : register()"
        >
          {{ window === 'signIn' ? '登入' : '註冊並登入' }}
        </VBtn>
      </VCardActions>
    </VCard>
    <MessageSnackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </VDialog>
</template>

<script type="module">
import Vue from 'vue';

import { blake3, md5 } from 'hash-wasm';

// import MessageSnackbar, { MessageSnackbarType } from '@/mixins/MessageSnackbarMixin';
import InputRulesMixin from '@/mixins/InputRulesMixin';
import MessageSnackbar from '@/mixins/MessageSnackbarMixin';
import getAPI from '@/axios-api';

import { delay } from '@/utils';

export default Vue.extend({
  name: 'AppSignInRegisterDialog',
  mixins: [InputRulesMixin, MessageSnackbar],
  props: {
    value: { type: Boolean, default: true },
  },
  data: () => ({
    // TODO: make localStorage as Vue plugin https://bit.ly/2DsS4ES
    localStorage: window.localStorage,
    currentLanguageName: 'English',
    languages: [
      {
        name: 'English',
        apply() {
          document.querySelector('html').setAttribute('lang', 'en');
        },
      },
      {
        name: '繁體中文',
        apply() {
          document.querySelector('html').setAttribute('lang', 'zh-Hant');
        },
      },
      {
        name: '简体中文',
        apply() {
          document.querySelector('html').setAttribute('lang', 'zh-Hans');
        },
      },
      {
        name: '日本語',
        apply() {
          document.querySelector('html').setAttribute('lang', 'ja');
        },
      },
      {
        name: '한국어',
        apply() {
          document.querySelector('html').setAttribute('lang', 'ko');
        },
      },
    ],

    window: 'signIn',
    signInData: {
      email: '',
      password: '',
    },
    registerData: {
      name: '',
      role: '',
    },
    areFormsValid: {
      signIn: false,
      register: false,
    },
    roles: ['醫師', '護理人員'],
    isSignInFormValid: false,
    isRegisterFormValid: false,
    isRememberMeChecked: false,
    isPasswordShowable: false,
    passwordShowing: false,
    requesting: false,
    authenticated: false,
    created: false,
  }),
  computed: {
    // authPersistence() {
    //   // TODO - remember me
    // },
  },
  created() {
    this.isRememberMeChecked =
      JSON.parse(localStorage.getItem('signIn.isRememberMeChecked')) === true;
    this.signInData.email = localStorage.getItem('signIn.email') ?? '';
  },
  methods: {
    async getGravatarURL(email) {
      const { protocol } = window.location;
      const hashed = await md5(email.trim().toLowerCase());
      return `${protocol}//www.gravatar.com/avatar/${hashed}?d=identicon&r=g`;
    },
    async preprocessData() {
      this.signInData.email = this.signInData.email.trim();
      this.signInData.password = await blake3(this.signInData.password);
      this.registerData.name = this.registerData.name?.trim();
      this.registerData.photoURL = this.registerData.photoURL?.trim();
      if (_.isNil(this.registerData.photoURL) || this.registerData.photoURL === '')
        this.registerData.photoURL = await this.getGravatarURL(this.signInData.email);
    },
    async signIn() {
      // TODO - actually implement this in backend
      this.$_mixin_messageSnackbar_hide();
      this.requesting = true;
      const { passwordShowing } = this;
      this.passwordShowing = false;
      await this.preprocessData();
      await delay(1000);
      const profile = {
        name: this.registerData.name,
        email: this.signInData.email,
        photoURL: this.registerData.photoURL,
      };
      // Make Django and Vue have the same password
      // email: demo@demo.com
      // password: 000000
      if (
        this.signInData.password ===
        'aa9c09f26292924ea7515d7ecc5695a76302f05ebf15bc1af88ec09b0676c6e5'
      ) {
        this.signInData.password = '000000';
      }
      await getAPI
        .post('/auth/login/', {
          email: this.signInData.email,
          password: this.signInData.password,
        })
        .then(async (res) => {
          if (res.status === 200) {
            this.isAuthenticated = true;
            console.log(res.data);
            this.$store.commit('updateToken', {
              access: res.data.access_token,
              refresh: res.data.refresh_token,
            });
            await this.$store.dispatch('fetchPatientInfo');
          }
        })
        .catch((err) => {
          console.log(err);
        });
      if (this.isAuthenticated) {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
        this.$store.commit('sign', profile);
        this.$store.state.profile.name = profile.name;
        this.$emit('input', false);
        this.signInData.password = '';
        this.passwordShowing = passwordShowing;
        this.requesting = false;
      } else {
        this.signInData.password = '';
        this.requesting = false;
      }
    },
    async register() {
      // TODO - actually implement this in backend
      this.$_mixin_messageSnackbar_hide();
      this.requesting = true;
      const { passwordShowing } = this;
      this.passwordShowing = false;
      await this.preprocessData();
      await delay(1000);
      const profile = {
        name: this.registerData.name,
        email: this.signInData.email,
        photoURL: this.registerData.photoURL,
      };
      await getAPI
        .post('/auth/registration', {
          username: this.registerData.name,
          email: this.signInData.email,
          role: this.registerData.role, // <------ set to doctor?
          password: this.signInData.password,
          password2: this.signInData.password,
        })
        .then((res) => {
          if (res.status === 201) {
            this.created = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.created = true;
      if (this.created) {
        this.$store.commit('sign', profile);
        this.$emit('input', false);
        this.signInData.password = '';
        this.passwordShowing = passwordShowing;
        this.requesting = false;
      } else {
        this.requesting = false;
      }
    },
  },
});
</script>
