<template>
  <div class="app-search-dialogue">
    <VDialog persistent :value="value" max-width="540px">
      <VCard>
        <VToolbar dense dark class="primary">
          <VToolbarTitle>查詢病歷</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="close()">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VForm ref="form" v-model="isValid">
          <VContainer>
            <VRow>
              <VCol>
                <VTextField
                  id="medicalOrderNumber"
                  v-model="searchInfo.medicalOrderNumber"
                  clearable
                  label="病歷號"
                  name="medicalOrderNumber"
                  prepend-icon="mdi-clipboard-account"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                  id="name"
                  v-model="searchInfo.name"
                  clearable
                  label="姓名"
                  name="name"
                  prepend-icon="mdi-account-circle"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol class="col-2">掛號日期</VCol>
              <VCol class="col-4">
                <VTextField
                  id="registerTimeStart"
                  v-model="searchPeriod.registerTimeStart"
                  clearable
                  label="從"
                  name="registerTimeStart"
                  type="date"
                />
              </VCol>
              <VCol class="col-4">
                <VTextField
                  id="registerTimeEnd"
                  v-model="searchPeriod.registerTimeEnd"
                  clearable
                  label="到"
                  name="registerTimeEnd"
                  type="date"
                />
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
        <VCardActions>
          <VSpacer />
          <VBtn @click="clear()">清除</VBtn>
          <VBtn type="submit" class="blue darken-1" text @click="submit()">提交</VBtn>
        </VCardActions>
      </VCard>
      <VOverlay v-model="requesting">
        <VProgressCircular indeterminate size="64" />
      </VOverlay>
    </VDialog>
  </div>
</template>

<script>
import Vue from 'vue';

import MessageSnackbar from '@/mixins/MessageSnackbarMixin';
import extensions from '@/mixins/ExtensionsMixin';
import inputRules from '@/mixins/InputRulesMixin';

export default Vue.extend({
  name: 'AppSearchDialog',
  mixins: [extensions, MessageSnackbar, inputRules],
  props: {
    value: { type: Boolean, default: true },
  },
  data: () => ({
    searchInfo: {
      name: '',
      medicalOrderNumber: '',
    },
    searchPeriod: {
      registerTimeStart: null,
      registerTimeEnd: null,
    },
    requesting: false,
    isValid: true,
  }),
  methods: {
    async submit() {
      console.log(this.searchInfo, this.searchPeriod);
      this.requesting = true;
      const searchDate = [this.searchPeriod.registerTimeStart, this.searchPeriod.registerTimeEnd];
      await this.$store.dispatch('searchPatientInfoData', searchDate);
      this.$store.dispatch('filterResultData', this.searchInfo);
      this.close();
      this.requesting = false;
    },
    clear() {
      this.searchInfo.name = '';
      this.searchInfo.medicalOrderNumber = '';
      this.searchPeriod.registerTimeStart = null;
      this.searchPeriod.registerTimeEnd = null;
      this.$store.dispatch('clearSearchResultData');
      this.close();
    },
    close() {
      this.$emit('input', false);
    },
  },
});
</script>
