<template>
  <VDialog v-model="dialogOpened" persistent max-width="1400px">
    <VCard height="700px">
      <VToolbar dense color="primary">
        <VToolbarTitle>建議方藥</VToolbarTitle>
        <VSpacer />
        <VBtn outlined disabled>專家討論系統平台</VBtn>
        <VBtn icon @click="dialogOpened = false">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VContainer fluid>
        <VRow>
          <VCol cols="3">
            <VRadioGroup v-model="recCaseSelectMode" row>
              <VRadio label="AND" value="AND"></VRadio>
              <VRadio label="OR" value="OR"></VRadio>
            </VRadioGroup>
          </VCol>
          <VCol cols="9">
            <VAutocomplete
              v-model="recMedSelected"
              multiple
              :items="dummyRecMed"
              item-text="name"
              dense
              label="Search medicine"
              class="mr-3"
            ></VAutocomplete>
          </VCol>
        </VRow>
        <VDivider />
        <VRow class="pt-5">
          <VCol cols="3">
            <VDataTable
              hide-default-footer
              :headers="recHeaders"
              :items="dummyRecSelected"
              height="calc(100vh - 439px)"
              class="medication-table"
            >
              <template #[`item.action`]="{ item }">
                <VIcon small class="mr-2" @click="medicineDescOpen(item)">mdi-information</VIcon>
              </template>
            </VDataTable>
          </VCol>
          <VDivider vertical />
          <VCol cols="9">
            <VCard height="515px" style="overflow: auto">
              <VCard v-for="(c, index) in dummyRecCases" :key="index">
                <VCardTitle>{{ c.title }}</VCardTitle>
                <VCardText>{{ c.content }}</VCardText>
              </VCard>
            </VCard>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import Vue from 'vue';

import componentBus from '@/main';

export default Vue.extend({
  name: 'MedicationConsultation',
  data: () => ({
    dialogOpened: false,
    recCaseSelectMode: 'AND',
    recMedSelected: [],
    recHeaders: [
      {
        text: 'name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'min',
        sortable: false,
        value: 'min',
      },
      {
        text: 'max',
        sortable: false,
        value: 'max',
      },
      {
        text: 'cases',
        sortable: false,
        value: 'cases',
      },
      {
        text: 'info',
        sortable: false,
        value: 'action',
      },
    ],
  }),
  computed: {
    dummyRecMed() {
      const table = [];
      [...Array(20).keys()].forEach((i) => {
        table.push({
          name: `ex Medicine${i}`,
          max: `${(i % 3) + 5} 錢`,
          min: `${(i % 3) + 1} 錢`,
          cases: Math.round(((i + 3) / 17) * 30),
        });
      });
      return table;
    },
    dummyRecCases() {
      const cases = [];
      if (this.recMedSelected.length === 0) return cases;
      if (this.recCaseSelectMode === 'OR') {
        this.recMedSelected.forEach((med) => {
          cases.push({
            title: `Case 1 ${med}`,
            content: `This is a case example for ${med} for the OR feature. The contents will be filled with the txt files of cases that are previously available. Still not sure what the links will lead to but it will be implemented later on.`,
          });
          cases.push({
            title: `Case 2 ${med}`,
            content: `This is a case example for ${med} for the OR feature. The contents will be filled with the txt files of cases that are previously available. Still not sure what the links will lead to but it will be implemented later on.`,
          });
        });
      } else {
        let meds = '';
        this.recMedSelected.forEach((med) => {
          meds += `, ${med}`;
        });
        [...Array(3).keys()].forEach((i) => {
          cases.push({
            title: `Case ${i + 1} ${meds}`,
            content: `This is a case example for ${meds} for the AND feature. The contents will be filled with the txt files of cases that are previously available. Still not sure what the links will lead to but it will be implemented later on.`,
          });
        });
      }
      return cases;
    },
    dummyRecSelected() {
      const table = [];
      this.dummyRecMed.forEach((med) => {
        if (this.recMedSelected.find((name) => name === med.name)) {
          table.push(med);
        }
      });
      return table;
    },
  },
  created() {
    componentBus.$on('medication-consult', () => {
      this.dialogOpened = true;
    });
  },
  methods: {
    medicineDescOpen(item) {
      componentBus.$emit('medication-info', item);
    },
  },
});
</script>
